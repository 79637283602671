import React, { useEffect, useState } from 'react';
import { Col, Button, Form } from 'react-bootstrap';
import { isEmpty, orderBy } from 'lodash';
import { Divider } from '@mui/material';
import './styles.scss';
import { history } from '../../../utils/history';
import {
  MdAdd,
  MdClearAll,
  MdDelete,
  MdFilter,
  MdList,
  MdSearch,
} from 'react-icons/md';
import { routes } from '../../../routes/index';
import Loading from '../../../utils/loader';
import PastPaperCard from './NationalPaperCard';
import SelectInputField from '../../common/SelectInputField';
import PageContentNotFound from '../../common/PageNotFound';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import SpinnerButton from '../../common/SpinnerButton';
import { generateDropdownRange } from '../../../utils/generateDropdownRange';
import { selectDropdownMapper } from '../../../utils/selectDropdownMapper';
import urls from '../../../utils/uri';
import http from '../../../utils/http';
import { decryptKey, isCMSAdmin } from '../../../utils/authHelpers';
import classNames from 'classnames';
import moment from 'moment';
import useToggle from '../../../hooks/useToggle';
import { useSelector } from 'react-redux';
import { statusSelector } from '../../../features/status/statusSlice';
import { curriculumSelector } from '../../../features/curriculum/curriculumSlice';
import { quizTypeSelector } from '../../../features/quizTypes/quizTypeSlice';
import { userSelector } from '../../../features/users/userSlice';
import { FaCompressAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const searchOptions = [
  { label: 'Search by paper title', value: 'Title' },
  { label: 'Search by paper code', value: 'Quiz Code' },
];

const paginationArgs = { limit: 1, offset: 0 };
const date = new Date();
const maxYear = date.getFullYear();
const minYear = 1989;

const ListPastPapers = (props) => {
  const { statuses } = useSelector(statusSelector);
  const { selectedHierarchy } = useSelector(curriculumSelector);
  const { quizTypes } = useSelector(quizTypeSelector);
  let { users } = useSelector(userSelector);

  if (!isEmpty(users)) {
    users = orderBy(selectDropdownMapper(users), 'label');
  }
  const subjects = !isEmpty(selectedHierarchy)
    ? selectDropdownMapper(selectedHierarchy?.subjects)
    : [];

  const [state, setState] = useState({
    searchTerm: '',
    quizTypeId: '',
    selectedStatus: 'all',
    offset: 0,
    limit: 10,
    searchOption: {},
    start_date: moment(date)
      .subtract(1, 'month')
      .startOf('day')
      .format('MM/DD/YYYY'),
    end_date: moment(date).add(1, 'day').endOf('day').format('MM/DD/YYYY'),
  });

  const [loading, toggleLoading] = useToggle();

  const onChange = ({ target }) => {
    const { name, value } = target;
    if (!value) {
      loadQuizzes();
    }
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const onSearch = async (e) => {
    e.preventDefault();
    await onFilter();
  };

  const onSearchOptionChange = (value) => {
    setState((prev) => ({ ...prev, searchOption: value }));
  };

  const onChangeStatus = (status) => {
    let status_ids = [];
    if (status === 'all') {
      status_ids = statuses
        .filter((s) => s.name.toLowerCase() !== 'deleted')
        .map((s) => s.id);
    } else {
      status_ids = statuses
        .filter((s) => s.name.toLowerCase() === 'deleted')
        .map((s) => s.id);
    }
    setState((prev) => ({ ...prev, selectedStatus: status }));
    loadQuizzes({ status_ids });
  };

  const onFilter = async () => {
    const { searchTerm, searchOption } = state;
    let params = {};
    if (searchTerm) {
      params = { ...params, code: searchTerm };
      if (searchOption.value === 'Title') {
        params = { title: searchTerm };
      } else {
        params = { code: searchTerm };
      }
      await loadQuizzes(params);
    }
  };

  const handlePagination = async (next = true) => {
    let { offset, limit } = state;
    if (next === false) {
      offset = offset - limit;
    } else offset = offset + limit;
    await setState((prev) => ({ ...prev, offset, limit }));
    await loadQuizzes();
  };

  const onRefersh = (quiz) => {
    setState((prev) => ({
      ...prev,
      quizzes: state.quizzes.filter((q) => q?.id !== quiz?.id),
    }));
  };

  const getYearTagType = async () => {
    const result = await http.get(
      { name: 'year', ...paginationArgs },
      `${urls.cms}tag-type`
    );
    if (!result?.errored) {
      setState((prev) => ({
        ...prev,
        yearTagTypeId: result?.returned_resultset[0]?.id,
      }));
    }
  };

  const getNationalPaperQuizType = () => {
    let name = 'National Past Papers';
    if (isEnezaPaper) name = 'Eneza National Past Papers';
    return quizTypes?.find(
      (type) => type?.name?.toLowerCase() === name.toLowerCase()
    );
  };

  const loadQuizzes = async (params = {}) => {
    const userInfo = decryptKey();
    let subject_ids = [];
    if (!isEmpty(subjects)) {
      subject_ids = subjects.map((subject) => subject?.id);
    }
    await toggleLoading(true);

    const { limit, offset, user } = state;
    let queryParams = {
      limit,
      offset,
      subject_ids,
    };
    if (!isEmpty(quizTypes)) {
      queryParams = {
        ...queryParams,
        quiz_type_id: Number(getNationalPaperQuizType()?.id),
      };
    }

    if (!isCMSAdmin()) {
      queryParams.created_by = userInfo?.user?.id;
    }
    if (!isEmpty(user)) {
      queryParams.created_by = user.value;
    }
    if (!isEmpty(params)) {
      queryParams = { ...queryParams, ...params };
    }
    if (isEmpty(queryParams.status_ids)) {
      const status_ids = statuses
        ?.filter((s) => s.name?.toLowerCase() !== 'deleted')
        ?.map((s) => s.id);
      queryParams.status_ids = status_ids;
    }
    const result = await http.get({ ...queryParams }, `${urls.cms}quiz`);
    if (result?.errored) {
      toggleLoading(false);
    }
    let quizResult = result?.returned_resultset;
    setState((prev) => ({
      ...prev,
      quizzes: quizResult,
      size: result?.returned_resultset_size,
      available: result.available_resultset_size,
    }));
    toggleLoading(false);
  };

  const handleGetTag = async (event) => {
    setState((prev) => ({ ...prev, year: event }));
    const tag = await http.get(
      { name: event.value, ...paginationArgs },
      `${urls.cms}tag`
    );
    if (tag.returned_resultset) {
      setState((prev) => ({
        ...prev,
        tag_ids: [tag.returned_resultset[0]?.id],
      }));
    }
  };

  const onDateChange = (event, picker) => {
    setState((prev) => ({
      ...prev,
      start_date: moment(picker.startDate).format('MM/DD/YYYY'),
      end_date: moment(picker.endDate).format('MM/DD/YYYY'),
    }));
  };

  const handleFilter = async () => {
    const {
      year,
      subject,
      level,
      tag_ids,
      user,
      limit,
      offset,
      // start_date,
      // end_date,
    } = state;
    if (year && isEmpty(tag_ids)) {
      return setState((prev) => ({ ...prev, quizzes: [] }));
    }
    const params = {
      subject_ids: !isEmpty(subject) ? [subject.id] : [],
      level_ids: !isEmpty(level) ? [level?.id] : [],
      tag_ids,
      created_by: user?.id,
      // start_date: moment(start_date).startOf('day').toISOString(),
      // end_date: moment(end_date).endOf('day').toISOString()
    };
    Object.keys(params).forEach((key) => {
      if (!params[key] || isEmpty(params[key])) delete params[key];
    });
    setState((prev) => ({ ...prev, quizzes: [] }));
    await loadQuizzes({ ...params, limit, offset });
  };

  const onClearFilters = async () => {
    setState((prev) => ({
      ...prev,
      year: '',
      subject: {},
      level: {},
      tag_ids: [],
      user: {},
    }));
    await loadQuizzes();
  };

  useEffect(() => {
    if (isEmpty(selectedHierarchy)) {
      history.push(routes.courseSelector);
    }
  }, [selectedHierarchy]);

  const onLoad = async () => {
    await toggleLoading(true);
    await loadQuizzes();
    await toggleLoading(false);
    await getYearTagType();
  };

  useEffect(() => {
    onLoad();
  }, []);

  const {
    searchTerm,
    loading: inProgress,
    working,
    searchOption,
    quizzes,
    user,
    subject,
    year,
    offset,
    start_date,
    end_date,
    selectedStatus,
    limit,
    yearTagTypeId,
    size: returned_resultset_size,
    available: available_resultset_size,
  } = state;
  const {
    location: { pathname },
  } = props;

  const isEnezaPaper = pathname?.includes('eneza');

  return (
    <div className='content'>
      <div className='quiz-content'>
        <div>
          <div>
            <div className='content__title-container mb-2'>
              <h3 className='content__title'>
                {!isEnezaPaper
                  ? 'KNEC National Papers'
                  : 'Eneza National Papers'}
              </h3>
              <Button
                className='btn-primary'
                onClick={() =>
                  history.push(
                    !isEnezaPaper
                      ? routes.addNationalPaper
                      : routes.addEnezaNationalPaper
                  )
                }
              >
                <MdAdd />
                {!isEnezaPaper ? 'Add KNEC Paper' : 'Add Eneza Paper'}
              </Button>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <Form onSubmit={onSearch}>
                <div className='row my-4'>
                  <div className='col-4'>
                    <SelectInputField
                      placeholder='Select a search option'
                      options={searchOptions}
                      onChange={onSearchOptionChange}
                    />
                  </div>

                  <div className='col-6'>
                    <Form.Control
                      id='input'
                      type='text'
                      onChange={onChange}
                      placeholder={searchOption?.label || 'Search'}
                      value={searchTerm}
                      name='searchTerm'
                    />
                  </div>
                  <div className='col-2'>
                    <Button
                      block
                      className='btn-primary'
                      type='submit'
                      onClick={onSearch}
                    >
                      <MdSearch />
                      Search
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
          <div className='row mb-2'>
            {!isEnezaPaper && (
              <div className='col-md-2'>
                <SelectInputField
                  placeholder='Year'
                  className='select-input-100'
                  value={year}
                  options={generateDropdownRange(minYear, maxYear)}
                  onChange={(event) => handleGetTag(event)}
                />
              </div>
            )}
            <div className={!isEnezaPaper ? 'col-md-2' : 'col-md-3'}>
              <SelectInputField
                className='select-input-100'
                placeholder='Subject'
                value={subject}
                options={subjects}
                onChange={(value) =>
                  setState((prev) => ({ ...prev, subject: value }))
                }
              />
            </div>
            <div className={!isEnezaPaper ? 'col-md-3' : 'col-md-4'}>
              <DateRangePicker
                onApply={onDateChange}
                initialSettings={{
                  startDate: start_date,
                  endDate: end_date,
                }}
              >
                <input
                  type='text'
                  placeholder='Date Created'
                  className='form-control'
                  onChange={onDateChange}
                />
              </DateRangePicker>
            </div>
            <div className='col-md-3'>
              <SelectInputField
                className='select-input-100'
                placeholder='Created By'
                value={user}
                onChange={(value) =>
                  setState((prev) => ({ ...prev, user: value }))
                }
                options={users}
              />
            </div>
            <div className='col-md-2 d-flex'>
              <SpinnerButton
                block
                type='button'
                working={working}
                onClick={handleFilter}
              >
                <MdFilter /> Filter
              </SpinnerButton>
              {!working && (
                <Button className='btn-secondary ml-2' onClick={onClearFilters}>
                  <MdClearAll /> Clear
                </Button>
              )}
            </div>
          </div>
          <div className='cb-tabs'>
            <Button
              variant='link'
              className={classNames(
                selectedStatus === 'all' && 'selected-status'
              )}
              onClick={() => {
                onChangeStatus('all');
              }}
            >
              <MdList /> Quizzes
            </Button>
            <Button
              variant='link'
              className={classNames(
                selectedStatus === 'deleted' && 'selected-status'
              )}
              onClick={() => {
                onChangeStatus('deleted');
              }}
            >
              <MdDelete /> Trashed
            </Button>
            <Link to={routes.courseSelector}>
              <FaCompressAlt className='icon' />
              Switch Course
            </Link>
          </div>
          {available_resultset_size > limit && (
            <div className='pagination'>
              <Button
                variant='link'
                className='btn-link'
                onClick={() => handlePagination(false)}
                disabled={offset === 0}
              >
                &larr;Prev
              </Button>
              <Button
                variant='link'
                className='btn-link'
                disabled={
                  returned_resultset_size < limit ||
                  available_resultset_size < limit
                }
                onClick={() => handlePagination(true)}
              >
                Next &rarr;
              </Button>
            </div>
          )}
        </div>

        <div className=' row quizzes-table-header'>
          <Col md={!isEnezaPaper ? 2 : 3} className='topic-item'>
            Title
          </Col>
          {!isEnezaPaper && (
            <Col md={1} className='topic-item'>
              Year
            </Col>
          )}
          <Col md={2} className='topic-item'>
            Subject
          </Col>
          <Col md={2} className='topic-item'>
            Created By
          </Col>
          <Col md={2} className='topic-item'>
            Paper Code
          </Col>
          <Col md={1} className='topic-item'>
            Published Questions
          </Col>
          <Col md={1} className='topic-item'>
            Total Questions
          </Col>
          <Col md={1} className='topic-item'>
            Date
          </Col>
        </div>
        <Divider className='my-2' />
        {loading || inProgress ? (
          <Loading />
        ) : (
          <div
            className={classNames(
              'past-paper-list',
              isEmpty(quizzes) && 'past-paper-list__auto'
            )}
          >
            {!isEmpty(quizzes) ? (
              quizzes.map((quiz) => {
                return (
                  <div key={quiz?.id}>
                    <PastPaperCard
                      quiz={quiz}
                      users={users}
                      yearTagTypeId={yearTagTypeId}
                      refresh={onRefersh}
                      statuses={statuses}
                      pathname={pathname}
                      selectedStatus={selectedStatus}
                    />
                  </div>
                );
              })
            ) : (
              <PageContentNotFound
                className='mt-5'
                status='404'
                title='Not found'
                subTitle={`There are no ${
                  !isEnezaPaper ? 'KNEC' : 'Eneza'
                } national papers available`}
                extra={
                  <Button
                    onClick={() =>
                      history.push(
                        !isEnezaPaper
                          ? routes.addNationalPaper
                          : routes.addEnezaNationalPaper
                      )
                    }
                    type='primary'
                  >
                    {!isEnezaPaper ? 'Add KNEC Paper' : 'Add Eneza Paper'}
                  </Button>
                }
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ListPastPapers;
