import { Box, Card, Typography } from '@mui/material';
import React from 'react';
import { Button, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setSelectedCourse } from '../../../features/course/courseSlice';
import { curriculumSelector, setSelectedHierarchy } from '../../../features/curriculum/curriculumSlice';
import { routes } from '../../../routes';
import { history } from '../../../utils/history';

const CourseSelector = () => {
  const dispatch = useDispatch();
  const {  curriculumHierarchy } = useSelector(curriculumSelector);


  const handleSelectCourse = (value = '') => {
    const selectedHierarchy = curriculumHierarchy.find(
      (h) => h?.course?.name.toLowerCase() === value.toLowerCase()
    );
    dispatch(setSelectedHierarchy(selectedHierarchy));
    dispatch(setSelectedCourse(value));
    history.push(routes.listNationalPapers)
  };

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card
        sx={{
          margin: 'auto',
          width: 500,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: 200,
        }}
        elevation={0}
      >
        <Typography>Select Course</Typography>
        <Row>
          <Button style={{width: '100px'}} className='mr-3'  variant='primary' onClick={() => handleSelectCourse('Primary')}>KCPE</Button>
          <Button style={{width: '100px'}}  variant='warning' onClick={() => handleSelectCourse('Secondary')}>KCSE</Button>
        </Row>
      </Card>
    </Box>
  );
};

export default CourseSelector;
