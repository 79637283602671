import React from 'react';
import Logo from '../../assets/logo.png';
import './styles.scss';
import classNames from 'classnames';

export default function PageContentNotFound({
  title,
  subTitle,
  extra,
  className,
  status,
}) {
  return (
    <div className={classNames('page-not-found', className)}>
      <div>
        <img src={Logo} alt='' height={100} width={100} />
        <h2>{status}</h2>
        <p>{title}</p>
        <div style={{width: "70%", margin: 'auto'}}>
          <h5>{subTitle}</h5>
        </div>
        {extra}
      </div>
    </div>
  );
}
