import { Alert } from '@mui/material';
import React from 'react';
import './styles.scss';

export default function ErrorMessage({ message: err }) {
  let error;
  if (typeof err === 'string') error = err;
  else error = err?.message;
  return (
    <div className='error-message'>
      <Alert severity='error' className='my-2'>
        {error}
      </Alert>
    </div>
  );
}
