import axios from './net'

const {
  REACT_APP_CMS_BASE_URL: baseURL,
} = process.env;

export const cmsAxiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
})