import { createSlice } from '@reduxjs/toolkit';
import { logout } from '../../utils/authHelpers';

const initialState = { user: '', token: '' };

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { user, token } = action.payload;
      state.user = user;
      state.token = token;
    },
    logOut: (state) => {
      state.user = null;
      state.token = null;
      logout()
    },
  },
});

export const { logOut, setCredentials } = authSlice.actions;
export default authSlice.reducer;
