/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { debounce, isEmpty } from 'lodash';
import EditOptionCard from './edit-option-card-component';
import QuestionAPI from '../../../api/question';
import FormModalComponent from '../../common/FormModalComponent';
import ErrorMessage from '../../common/ErrorMessage';
import { MdDelete, MdDragHandle } from 'react-icons/md';
import OptionAPI from '../../../api/option';
import { Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { selectDropdownMapper } from '../../../utils/selectDropdownMapper';
import useToggle from '../../../hooks/useToggle';
import useError from '../../../hooks/useError';
import http from '../../../utils/http';
import urls from '../../../utils/uri';

const EditQuestionCard = (props) => {
  const [state, setState] = useState({
    question: props.question,
    questionText: props.question.body,
    viewOptions: false,
    validationError: '',
    questionError: '',
    updateOption: false,
    showAttachTopicModal: false,
    showUpdateQuestionModal: false,
    correctOption: {},
    showDeleteQuestionModal: false,
  });

  const [working, toggleWorking] = useToggle();
  const [error, handleError] = useError();

  useEffect(() => {
    if (!isEmpty(props.question?.options)) {
      setState((prev) => ({
        ...prev,
        correctOption: props.question.options.find(
          (option) => option.is_correct === true
        ),
      }));
    }
  }, [props.question?.options]);

  useEffect(() => {
    const { question, deletedStatus } = props;
    if (!isEmpty(question) && !isEmpty(deletedStatus)) {
      const { options } = question;
      const correctOption =
        !isEmpty(options) &&
        options
          .filter(
            (option) =>
              parseInt(option.status_id) !== parseInt(deletedStatus.id)
          )
          .find((option) => option.is_correct === true);
      setState((prev) => ({ ...prev, correctOption }));
    }
  }, [props.question, props.deletedStatus]);

  const onToggleModal = (name) => {
    setState((prevState) => ({
      [name]: !prevState.name,
    }));
  };

  const onCloseModal = () => {
    setState({
      showUpdateQuestionModal: false,
      showDeleteQuestionModal: false,
      showAttachTopicModal: false,
    });
  };

  const onChange = ({ target }) => {
    const { name, value } = target;
    setState(
      {
        [name]: value,
      },
      
    );
  };

  useEffect(() => {
      if (!props.isPastPaper) {
        if (state.questionText && state.questionText.length > 100) {
          setState({
            questionError: 'Question text exceeds character limit',
          });
        } else if (state.questionText && state.questionText.length === 0) {
          setState((prev) => ({
            ...prev,
            questionError: 'Question text cannot be empty',
          }));
        } else setState((prev) => ({ ...prev, questionError: '' }));
      }
  }, [props.isPastPaper])

  const onViewOptions = () => {
    setState((prevState) => ({
      viewOptions: !prevState.viewOptions,
    }));
  };

  const handleValidationError = (error) => {
    setState((prev) => ({ ...prev, validationError: error }));
  };

  const getQuestion = async () => {
    const { question: SelectedQuestion } = props;
    try {
      toggleWorking(true);
      const response = await http.get({}, `${urls.cms}/question/${SelectedQuestion.id}`)
      if (response?.error) {
        toggleWorking(false);
        return handleError(response.error);
      }
      toggleWorking(false);
      const question = response?.returned_resultset;
      setState({
        question,
        questionText: question.body,
      });
    } catch (error) {
      toggleWorking(false);
      handleError(error);
    }
  };

  const onDeleteQuestion = async () => {
    try {
      const { statuses, question, onSuccess, refresh } = props;
      const { id, options } = question;
      const deleteStatus = statuses.find((status) => status.name === 'DELETED');
      toggleWorking(true);
      const {error} = await http.get({status_id: !isEmpty(deleteStatus)
        ? deleteStatus.id
        : question.status_id}, `${urls.cms}question/${id}`)
      
      if (error) {
        toggleWorking(false);
        return handleError(error);
      }
      const deleteOptionsArr = []
      options.forEach(option => {
        if(!isEmpty(option)) {
          deleteOptionsArr.push(http.delete({}, `${urls.cms}option/${option.id}`))
        }
      }) 
      if(!isEmpty(deleteOptionsArr)) {
        await Promise.all([deleteOptionsArr])
      }
      onCloseModal();
      onSuccess('Question has been successfully deleted!');
      await refresh();
    } catch (error) {
      toggleWorking(false);
      handleError(error);
    }
  };

  const onSelectOption = (option) => {
    setState((prev) => ({ ...prev, correctOption: option }));
  };

  const onUpdate = async () => {
    const { question, refresh, onSuccess } = props;
    const { questionText, validationError, questionError } = state;
    if (validationError || questionError) return;
    const api = new QuestionAPI();
    try {
      setState((prev) => ({ ...prev, updateOption: true }));
      toggleWorking(true);
      const { error } = await api.updateQuestion(question?.id, {
        body: questionText,
      });
      if (error) {
        toggleWorking(false);
        return handleError(error);
      }
      toggleWorking(false);
      onCloseModal();
      onSuccess('Question has been successfully updated!');
      await refresh();
    } catch (error) {
      toggleWorking(false);
      handleError(error);
    }
  };

  const {
    question,
    viewOptions,
    updateOption,
    questionText,
    showUpdateQuestionModal,
    showDeleteQuestionModal,
    correctOption,
    validationError,
    questionError,
  } = state;
  const { idx, statuses, onSuccess, deletedStatus, isPastPaper } =
    props;
 
  const canUpdate = question?.body !== questionText;
  const { options } = question || {};
  return (
    <Draggable draggableId={question?.id} index={idx}>
      {(provided) => (
        <Row
          className='edit-question-card'
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <FormModalComponent
            title='Confirm Update'
            onSubmit={showUpdateQuestionModal ? onUpdate : onDeleteQuestion}
            show={showUpdateQuestionModal || showDeleteQuestionModal}
            working={working}
            onHide={onCloseModal}
          >
            {!isPastPaper && (validationError || questionError) && (
              <ErrorMessage message={validationError || questionError} />
            )}
            <p>
              {showUpdateQuestionModal
                ? 'Are you sure you want to update this question?'
                : 'Are you sure you want to delete this question and its options? This cannot be undone!'}
            </p>
          </FormModalComponent>
          <Col xs={12}>{error && <ErrorMessage message={error} />}</Col>
          <Col md={8} className='draggable-card'>
            <Form.Group>
              <div className='question-body__label'>
                <Form.Label>
                  <MdDragHandle
                    title='re order question'
                    className='draggable-icon'
                  />{' '}
                  {`Question ${idx + 1}`}
                </Form.Label>
                <div
                  role='button'
                  onClick={() => onToggleModal('showDeleteQuestionModal')}
                  className='question-delete'
                >
                  <MdDelete /> Delete Question
                </div>
              </div>
              <Form.Control
                name='questionText'
                onChange={onChange}
                isInvalid={!!questionError}
                value={questionText}
              />
              {!isPastPaper && canUpdate && !questionError ? (
                <Form.Text>
                  Total number of chars in Question: {questionText.length}/100
                </Form.Text>
              ) : (
                <Form.Control.Feedback type='invalid'>
                  {questionError}
                </Form.Control.Feedback>
              )}
              <div className='view-options mt-2'>
                {!isEmpty(options) && (
                  <Button
                    variant='link'
                    className='btn-link'
                    onClick={onViewOptions}
                  >
                    {viewOptions ? 'Hide options' : 'View options'}
                  </Button>
                )}
                {canUpdate && !viewOptions && (
                  <Button
                    className='btn-primary'
                    disabled={questionError || validationError}
                    onClick={() => onToggleModal('showUpdateQuestionModal')}
                  >
                    Update Question
                  </Button>
                )}
              </div>
            </Form.Group>
          </Col>
          <Col md={12}>
            {viewOptions && (
              <>
                {!isEmpty(options) &&
                  options
                    .filter(
                      (option) =>
                        parseInt(option.status_id) !==
                        parseInt(deletedStatus.id)
                    )
                    .map((option, idx) => (
                      <EditOptionCard
                        statuses={statuses}
                        isPastPaper={isPastPaper}
                        refresh={getQuestion}
                        onSuccess={onSuccess}
                        onError={handleValidationError}
                        key={option.id}
                        correctOption={correctOption}
                        onSelectOption={onSelectOption}
                        updateOption={updateOption}
                        idx={idx}
                        option={option}
                      />
                    ))}
              </>
            )}
          </Col>
          <Col md={8}>
            <div className='view-options mt-2'>
              {viewOptions && (
                <Button
                  className='btn-primary'
                  onClick={() => onToggleModal('showUpdateQuestionModal')}
                  disabled={questionError || validationError}
                >
                  Update Question
                </Button>
              )}
            </div>
          </Col>
        </Row>
      )}
    </Draggable>
  );
};

export default EditQuestionCard;
