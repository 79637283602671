import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = { curriculums: [], curriculumHierarchy: [], selectedHierarchy: {} };

export const curriculumSlice = createSlice({
  name: 'curriculum',
  initialState,
  reducers: {
    setCurriculums: (state, { payload }) => {
      state.curriculums = payload;
    },
    setCurriculumHierarchy: (state, { payload }) => {
      state.curriculumHierarchy = payload;
    },
    setSelectedHierarchy: (state, { payload }) => {
      state.selectedHierarchy = payload;
    },
  },
});

export const { setCurriculums, setCurriculumHierarchy, setSelectedHierarchy } = curriculumSlice.actions;
const getState = (state) => state
export const curriculumSelector = createSelector(getState,  state => state.curriculum)

export default curriculumSlice.reducer;
