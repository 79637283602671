import React, { useEffect, useState } from 'react';
import { Col, Form, Image } from 'react-bootstrap';
import { FormControlLabel, Checkbox } from '@mui/material';
import { MdFileUpload } from 'react-icons/md';
import { isEmpty } from 'lodash';
import TextEditor from '../../../common/TextEditor';

export default function EditOptionCard({
  option: optionPayload,
  idx,
  invalidOptionError,
  handleOptionChange,
  setInvalidOptionError,
  handleCorrectAnswerChange,
  onOptionFileChange,
}) {
  const [option, setOption ]  = useState(() => optionPayload)

  useEffect(() => {
    if(!isEmpty(optionPayload)) {
      setOption(optionPayload)
    }
  }, [optionPayload])
  return (
    <>
      <Col key={idx} md={12}>
        <Form.Group>
          <Form.Label>{`Option ${option.label}`}</Form.Label>
          <TextEditor
            className='form-control'
            isInvalid={!!invalidOptionError}
            value={option?.body}
            placeholder={`Option ${option.label}`}
            onChange={e => {
              setOption({...option, body: e});
              handleOptionChange({ ...option, body: e }, idx);
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Explanation</Form.Label>
           <TextEditor
            className='form-control'
            value={option?.explanation}
            isInvalid={!!invalidOptionError}
            placeholder='Explanation'
            onChange={(e) => {
              setOption({...option, explanation: e});
              handleOptionChange({...option, explanation: e}, idx)}
            }
          />
          <Form.Control.Feedback type='invalid'>
            {invalidOptionError}
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col md={12}>
        <div className='option-addons'>
          <FormControlLabel
            control={
              <Checkbox
                type='checkbox'
                checked={option.is_correct}
                onChange={e => {
                  setInvalidOptionError('');
                  handleCorrectAnswerChange(e, idx);
                }}
                color='primary'
              />
            }
            label='Mark as Correct'
          />
          <div>
            <label htmlFor={`upload-button_${idx}`}>
              {option.preview || !isEmpty(option.attachments) ? (
                <>
                  <Image
                    src={option.preview ? option.preview : option.attachments[0]?.url}
                    width='100'
                    height='100'
                    rounded
                  />
                  <div role='button' className='file-upload-btn'>
                    Change Image
                  </div>
                </>
              ) : (
                <>
                  <div role='button' className='file-upload-btn'>
                    <MdFileUpload /> Attach image
                  </div>
                </>
              )}
            </label>
            <input
              type='file'
              id={`upload-button_${idx}`}
              style={{ display: 'none' }}
              onChange={e => onOptionFileChange(e, idx)}
            />
          </div>
        </div>
      </Col>
    </>
  );
}
