const parseResponse = async request => {
  try {
    const response = await request;
    return { payload: response.data };
  } catch (error) {
    if (error.response && error.response.data) {
      let errorMessage = error.response.data;
      if (errorMessage.message === 'Record not found') return { payload: [] };
      if (errorMessage.errors) {
        errorMessage = Object.values(errorMessage.errors).reduce(
          (err, e) => err + ', ' + e
        );
      }
      return {
        error: errorMessage,
      };
    } else {
      return { message: 'something went wrong, please try again later' };
    }
  }
};

export default parseResponse;
