import qs from 'querystring';
import { cmsApiSlice } from '../api/cmsApiSlice';
import { API_METHODS } from '../constants';

const { POST, PUT } = API_METHODS;

export const questionApiSlice = cmsApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getQuestions: builder.query({
      query: (params) => {
        const queryParams = qs.stringify(params);
        return `/question?${queryParams}`;
      },
    }),
    getQuestionTypes: builder.query({
      query: (params) => {
        const queryParams = qs.stringify(params);
        return `/question-type?${queryParams}`;
      },
    }),
    getQuestion: builder.query({
      query: (id) => {
        return `/question/${id}`;
      },
    }),
    createQuestion: builder.mutation({
      query: (body) => ({
        url: `/question`,
        method: POST,
        body,
      }),
    }),
    updateQuestion: builder.mutation({
      query: (body) => ({
        url: `/question/${body?.id}`,
        method: PUT,
        body,
      }),
    }),
    deleteQuestion: builder.mutation({
      query: (id) => ({
        url: `/question/${id}`,
        method: PUT,
      }),
    }),
  }),
});

export const { useGetQuestionTypesQuery, useGetQuestionQuery, useGetQuestionsQuery } = questionApiSlice;
