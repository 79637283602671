import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { MdCheckCircle, MdDelete } from 'react-icons/md';
import ConfirmModal from '../../common/ConfirmModal';
import OptionAPI from '../../../api/option';
import { FormControlLabel, Checkbox } from '@mui/material';
import { isEmpty } from 'lodash';
import useToggle from '../../../hooks/useToggle';
import useError from '../../../hooks/useError';
import { ErrorMessage } from 'formik';

const optionMatch = ['A', 'B', 'C', 'D', 'F'];
export default function EditOptionCard(props) {
  const [state, setState] = useState({
    option: props.option,
    optionBodyError: '',
    explanationError: '',
    optionBody: props.option.body,
    optionExplanation: props.option.explanation,
    showDeleteModal: false,
    updateOption: props.updateOption,
    showEditModal: false,
  });

  const [working, toggleWorking] = useToggle();
  const [error, handleError] = useError();

  const onToggleModal = (name) => {
    setState((prevState) => ({
      [name]: !prevState.name,
    }));
  };

  useEffect(() => {
    if (props.updateOption) {
      onUpdate();
    }
  }, [props.updateOption]);

  // const getOption = async () => {
  //   const { option: SelectedOption } = props;
  //   const api = new OptionAPI();
  //   try {
  //     toggleLoading(true);
  //     const { error, payload } = await api.getOption(SelectedOption.id);
  //     if (error) {
  //       toggleLoading(false);
  //       return handleError(error);
  //     }
  //     toggleLoading(false);
  //     const option = payload?.returned_resultset;
  //     setState(prev => ({...prev,
  //       option: option,
  //       optionBody: option.body,
  //       optionExplanation: option.explanation,
  //       isCorrect: option.is_correct,
  //     }));
  //   } catch (error) {
  //     toggleLoading(false);
  //     handleError(error);
  //   }
  // };

  const onChange = ({ target }) => {
    const { name, value } = target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (!props.isPastPaper) {
      if (state.optionExplanation.length > 250) {
        setState((prev) => ({
          ...prev,
          explanationError: 'Option explanation exceeds character limit',
        }));
      } else if (state.optionExplanation.length === 0) {
        setState((prev) => ({
          ...prev,
          explanationError: 'Option explanation cannot be empty',
        }));
      } else setState((prev) => ({ ...prev, explanationError: '' }));
      if (state.optionBody.length === 0) {
        setState((prev) => ({
          ...prev,
          optionBodyError: 'Option body cannot be empty',
        }));
      } else setState((prev) => ({ ...prev, optionBodyError: '' }));
    }
  }, [state.optionExplanation, state.optionBody, props?.isPastPaper]);
  const onDelete = async () => {
    const { option, refresh } = props;
    const api = new OptionAPI();
    try {
      toggleWorking(true);
      const { error } = await api.deleteOption(option.id);
      if (error) {
        toggleWorking(false);
        return handleError(error);
      }
      await refresh();
      toggleWorking(false);
      onCloseModal();
    } catch (error) {
      toggleWorking(false);
      handleError(error);
    }
  };
  const onUpdate = async () => {
    const { option, refresh, correctOption } = props;
    const { optionBody, optionExplanation } = state;
    const api = new OptionAPI();
    try {
      toggleWorking(true);
      const { error } = await api.updateOption(option.id, {
        body: optionBody,
        explanation: optionExplanation,
        is_correct: !isEmpty(correctOption) && correctOption.id === option.id,
      });
      if (error) {
        toggleWorking(false);
        return handleError(error);
      }
      await refresh();
    } catch (error) {
      toggleWorking(false);
      handleError(error);
    }
  };

  const onCloseModal = () => {
    setState(prev => ({...prev,
      showDeleteModal: false,
      showEditModal: false,
    }));
  };

  const handleChange = (e, option) => {
    setState(prev => ({...prev,
      checked: e.currentTarget.checked,
    }));
    props.onSelectOption(option);
  };
  const handleBlur = async () => {
    const { explanationError, optionBodyError } = state;
    if (explanationError || optionBodyError) {
      props.onError('Question option cannot be empty');
      return;
    }
    props.onError('');
    // await onUpdate();
  };
  const {
    optionBody,
    optionExplanation,
    showDeleteModal,
    showEditModal,
    explanationError,
    optionBodyError,
  } = state;
  const { idx, option, correctOption, isPastPaper } = props;
  let isCorrect = false;
  if (!isEmpty(option) && !isEmpty(correctOption)) {
    isCorrect = option.id === correctOption.id;
  }
  const { body, explanation } = option;
  const canUpdate = body !== optionBody || explanation !== optionExplanation;
  return (
    <Row>
      <ConfirmModal
        title={showDeleteModal ? 'Confirm Delete' : 'Confirm Update'}
        message={
          showDeleteModal
            ? 'Are you sure you want to delete this option? This action cannot be undone!'
            : 'Are you sure you want to update this option?'
        }
        onSubmit={showDeleteModal ? onDelete : onUpdate}
        show={showDeleteModal || showEditModal}
        working={working}
        onHide={onCloseModal}
      />
      <Col xs={12}>{error && <ErrorMessage message={error} />}</Col>

      <Col md={1}>
        <div className='option-label'>
          {optionMatch[idx]}
          {isCorrect && <MdCheckCircle className='is-correct' />}
        </div>
      </Col>
      <Col md={7}>
        <Form.Group>
          <Form.Control
            className='mb-2'
            name='optionBody'
            onChange={onChange}
            onBlur={handleBlur}
            value={optionBody}
            isInvalid={!isPastPaper && !!optionBodyError}
          />
          <Form.Control.Feedback type='invalid'>
            {optionBodyError}
          </Form.Control.Feedback>

          <Form.Control
            className='mb-2'
            name='optionExplanation'
            as='textarea'
            rows={4}
            onBlur={handleBlur}
            isInvalid={!isPastPaper && !!explanationError}
            onChange={onChange}
            value={optionExplanation}
          />
          {!isPastPaper && canUpdate && !explanationError ? (
            <Form.Text>
              {`Total number of chars in Explanation: ${optionExplanation.length}/250`}
            </Form.Text>
          ) : (
            <Form.Control.Feedback type='invalid'>
              {explanationError}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Col>
      {!isCorrect ? (
        <Col md={4}>
          <FormControlLabel
            control={
              <Checkbox
                type='checkbox'
                checked={state.checked && isCorrect}
                onChange={(e) => handleChange(e, option)}
                color='primary'
              />
            }
            label='Mark as Correct'
          />
          <div
            role='button'
            onClick={() => onToggleModal('showDeleteModal')}
            className='question-delete'
            aria-hidden='true'
          >
            <MdDelete /> Delete Option
          </div>
        </Col>
      ) : (
        <Col md={4}>
          <Form.Label>
            <MdCheckCircle className='is-correct' /> Correct Answer
          </Form.Label>
          <div
            aria-hidden='true'
            role='button'
            onClick={() => onToggleModal('showDeleteModal')}
            className='question-delete'
          >
            <MdDelete /> Delete Option
          </div>
        </Col>
      )}
    </Row>
  );
}
