import BaseAPI from './base';

export default class QuestionAPI extends BaseAPI {
  getQuestion = (id) => {
    const request = this.api.get(`/question/${id}`);
    return this.processRequest(request);
  };

  updateQuestion = (id, paylaod) => {
    const request = this.api.put(`/question/${id}`, {...paylaod})
    return this.processRequest(request);
  }

}
