import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = { users: [] };

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
  setUsers: (state, { payload }) => {
      state.users = payload;
    },
    
  },
});

export const { setUsers } = userSlice.actions;
const getStatus = (state) => state
export const userSelector = createSelector(getStatus,  state => state.user)

export default userSlice.reducer;
