import React, { useEffect, useState } from 'react';
import QuizAPI from '../../../api/quiz';
import { Button, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import EditQuestionCard from './edit-question-card.component';
import './styles.scss';
import Loading from '../../../utils/loader';
import LessonAPI from '../../../api/lesson';
import ConfirmModal from '../../common/ConfirmModal';
import { routes } from '../../../routes';
import { history } from '../../../utils/history';
import ToastContainer from '../../common/toast';
import { Checkbox, Divider, FormControlLabel } from '@mui/material';
import AddQuestion from '../Questions/add-question.component';
import FormModalComponent from '../../common/FormModalComponent';
// import { addQuestionsToQuiz } from '../../../redux/actions/quiz/add-quiz.actions';
import ErrorMessage from '../../common/ErrorMessage';

import { selectDropdownMapper } from '../../../utils/selectDropdownMapper';
import SelectInputField from '../../common/SelectInputField';
import { decryptKey, getCurriculum } from '../../../utils/authHelpers';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { debounce, orderBy } from 'lodash';

import http from '../../../utils/http';
import urls from '../../../utils/uri';
import useToggle from '../../../hooks/useToggle';
import useError from '../../../hooks/useError';
import { useSelector } from 'react-redux';
import { statusSelector } from '../../../features/status/statusSlice';
import { periodSelector } from '../../../features/period/periodSlice';
import { useLocation, useParams } from 'react-router-dom';
import { levelSelector } from '../../../features/level/levelSlice';
import { curriculumSelector } from '../../../features/curriculum/curriculumSlice';
import { useGetQuizQuery } from '../../../features/quiz/quizApiSlice';
import { addQuestionsToQuiz } from './queryHooks';

const curriculum = getCurriculum();
const EditQuizComponent = () => {
  const { statuses } = useSelector(statusSelector);
  let { periods } = useSelector(periodSelector);
  let { levels } = useSelector(levelSelector);
  const { curriculumHierarchy } = useSelector(curriculumSelector);

  const { state: locationState } = useLocation();
  const { id } = useParams();

  const [state, setState] = useState({
    quiz: {
      title: '',
      legacy_code: '',
    },
    isPastPaper: false,
    viewQuestions: false,
    showGoBackModal: false,
    lessonTitle: '',
    lessonBody: '',
    lesson: '',
    errors: {
      quizName: '',
      code: '',
      lessonBody: '',
    },
    quizName: '',
    showEditQuizModal: false,
    showEditLessonModal: false,
    showToast: false,
    showAddQuestionModal: false,
    subjects: [],
    subject: {},
    topics: [],
    code: '',
    level: {},
    levels: [],
    period: {},
    questions: [],
    dragged: false,
    topicTypeIds: [],
  });

  const [working, toggleWorking] = useToggle();
  const [error, handleError] = useError();

  const handleSelectedTopics = (topic) => {
    let selectedTopics = [];
    const topics = state.topics;
    topics.forEach((t) => {
      if (t.id === topic?.id) {
        t.selected = topic?.selected;
        selectedTopics.push(t);
      }
    });
    setState((prev) => ({ ...prev, topics, selectedTopics }));
  };

  const getLevels = (course) => {
    setState((prev) => ({ ...prev, levels: [] }));
    if (!isEmpty(course)) {
      const filteredLevels = levels?.filter((level) =>
        !isEmpty(course?.child_courses)
          ? course?.child_courses?.[0]?.id === level?.course_id
          : level?.course_id === course?.id
      );
      if (!isEmpty(filteredLevels)) {
        setState((prev) => ({
          ...prev,
          levels: selectDropdownMapper(filteredLevels),
        }));
      } else {
        setState((prev) => ({
          ...prev,
          levelError: 'There are no levels for the selected course!',
        }));
      }
    }
  };

  const searchTopics = async (name) => {
    const { subject } = state;
    if (!name) return;
    setState((prev) => ({ ...prev, topicNotFoundError: '' }));
    setState((prev) => ({ ...prev, isFetchingTopics: true }));
    const payload = {
      name,
      offset: 0,
      limit: 5,
      // topic_type_ids: topicTypeProps ? [...topicTypeProps] : [],
      subject_ids: subject?.id ? [subject?.id] : [],
    };
    const result = await http.get({ ...payload }, `${urls.cms}topic`);
    if (result?.errored) {
      setState((prev) => ({ ...prev, isFetchingTopics: false }));
      setState((prevState) => ({
        ...prevState,
        topics: prevState?.topics?.filter((topic) => topic?.selected),
      }));
      setState((prev) => ({
        ...prev,
        topicNotFoundError: 'There are no topics found. Please search again',
      }));
      return;
    }
    setState((prev) => ({ ...prev, isFetchingTopics: false }));
    setState((prevState) => ({
      ...prevState,
      topics: [
        ...prevState?.topics?.filter((topic) => topic?.selected),
        ...result?.returned_resultset,
      ],
    }));
  };

  const delayedTopicSearch = debounce((name) => {
    searchTopics(name);
  }, 1000);

  const onSearchTopics = (name) => {
    setState((prev) => ({ ...prev, searchTerm: name }));
    delayedTopicSearch(name);
  };

  const onSelectChange = (name) => (value) => {
    if (name === 'course') {
      setState((prev) => ({
        ...prev,
        subject: '',
        level: '',
        period: '',
        topics: [],
        levelError: '',
        subjectError: '',
        periodError: '',
        couseError: '',
      }));
      getLevels(value);
      const subjects = curriculumHierarchy?.find(
        (h) => h?.course?.id === value?.id
      )?.subjects;
      if (isEmpty(subjects)) {
        setState((prev) => ({
          ...prev,
          subjectError: 'Selected course does not have subjects',
          subjects: [],
        }));
      } else {
        setState((prev) => ({
          ...prev,
          subjects: selectDropdownMapper(subjects),
        }));
      }
    }
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const onChange = ({ target }) => {
    const { name, value } = target;
    setState((prev) => ({
      ...prev,

      [name]: value,
    }));
  };

  useEffect(() => {
    if (!state.isPastPaper) {
      if (state.quizName.length === 0) {
        setState((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            quizName: 'Quiz name cannot be empty',
          },
        }));
      } else
        setState((prev) => ({
          ...prev,
          errors: { ...prev.errors, quizName: '' },
        }));

      if (state.lessonBody?.length === 0) {
        setState((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            lessonBody: 'Lesson cannot be empty',
          },
        }));
      } else if (state.lessonBody?.length > 600) {
        setState((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            lessonBody: 'Lesson exceeds character limit',
          },
        }));
      } else
        setState((prev) => ({
          ...prev,
          errors: { ...prev.errors, lessonBody: '' },
        }));

      if (state.code?.length === 0) {
        setState((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            code: 'Quiz code cannot be empty',
          },
        }));
      } else
        setState((prev) => ({
          ...prev,
          errors: { ...prev.errors, code: '' },
        }));
    }
  }, [state.isPastPaper, state.code, state.lessonBody, state.quizName]);

  const onAddQuestionsToQuiz = (questions) => {
    setState((prev) => ({ ...prev, questions }));
    onToggleModal('showAddQuestionModal');
  };

  const { isLoading, data, refetch, isSuccess } = useGetQuizQuery(id, {
    skip: !id,
  });

  useEffect(() => {
    if (isSuccess) {
      const quiz = data?.returned_resultset;
      const { title, legacy_code, questions, subjects, lessons } = quiz;
      const [lesson] = lessons;
      let level = {};
      let period = {};
      let subject = {};
      if (!isEmpty(periods)) {
        const selectedPeriod = periods.find(
          (p) => p?.period_number === quiz?.periods?.[0]?.period_number
        );
        period = {
          ...selectedPeriod,
          label: `${selectedPeriod?.period_type?.name} ${selectedPeriod?.period_number}`,
          value: selectedPeriod?.id,
        };
      }
      if (!isEmpty(levels)) {
        const selectedLevel = levels.find(
          (l) => l?.id === quiz?.levels?.[0]?.id
        );
        level = {
          ...selectedLevel,
          label: selectedLevel?.level,
          value: selectedLevel?.id,
        };
      }

      if (!isEmpty(subjects)) {
        subject = {
          ...subjects?.[0],
          label: subjects?.[0]?.name,
          value: subjects?.[0]?.id,
        };
      }
      setState((prev) => ({
        ...prev,
        quiz,
        quizz: quiz,
        code: legacy_code,
        period,
        questions,
        level,
        quizName: title,
        subject,
        lessonBody: lesson?.body,
        lesson,
      }));
    }
  }, [isSuccess]);

  const onToggleModal = (name) => {
    setState((prevState) => ({
      ...prevState,
      [name]: !prevState.name,
    }));
  };

  const onCloseModal = () => {
    setState((prev) => ({
      ...prev,
      showEditQuizModal: false,
      showGoBackModal: false,
      showEditLessonModal: false,
      showToast: false,
      showAddQuestionModal: false,
      toastMessage: '',
      error: '',
    }));
  };

  useEffect(() => {
    if (locationState && locationState.pastPaper) {
      setState((prev) => ({
        ...prev,
        isPastPaper: locationState.pastPaper,
      }));
    }
  }, [locationState]);

  const onUpdateQuiz = async () => {
    const {
      quiz,
      quizName,
      code,
      level,
      period,
      isPastPaper,
      subject,
      topics,
    } = state;
    const api = new QuizAPI();
    const selectedTopics = topics
      ?.filter((topic) => topic?.selected)
      ?.map((topic) => topic.id);
    let body = {
      title: quizName,
      legacy_code: code,
      topic_ids: selectedTopics ? selectedTopics : [],
    };
    if (!isEmpty(level)) {
      body = {
        ...body,
        level_ids: [level?.id],
      };
    }
    if (!isEmpty(period)) {
      body = {
        ...body,
        period_ids: [period?.id],
      };
    }
    if (!isEmpty(subject)) {
      body = {
        ...body,
        subject_ids: [subject?.id],
      };
    }
    if (!isEmpty(quiz.levels)) {
      delete body.level_ids;
      body = {
        ...body,
        old_level_id: quiz.levels?.[0]?.id,
        new_level_id: level?.id,
      };
    }
    if (!isEmpty(quiz.periods)) {
      delete body.period_ids;
      body = {
        ...body,
        old_period_id: quiz.periods?.[0]?.id,
        new_period_id: period?.id,
      };
    }
    if (!isEmpty(quiz.topics) && topics) {
      delete body.topic_ids;
      delete body.topics;
      const selectedTopic = topics?.find((topic) => topic?.selected);
      if (selectedTopic) {
        body = {
          ...body,
          old_topic_id: quiz.topics?.[0]?.id,
          new_topic_id: selectedTopic?.id,
        };
      }
    }
    if (!isEmpty(quiz.subjects)) {
      delete body.subject_ids;
      body = {
        ...body,
        old_subject_id: quiz.subjects?.[0]?.id,
        new_subject_id: subject?.id,
      };
    }
    try {
      toggleWorking(true);
      if (isPastPaper) delete body.period_ids;
      const { error } = await api.updateQuiz(quiz?.id, {
        ...body,
      });
      if (error) {
        toggleWorking(false);
        return handleError(error);
      }
      toggleWorking(false);
      onCloseModal();
      onToggleToast(
        `${
          isPastPaper
            ? 'Past paper has been successfully updated'
            : 'Quiz settings has been successfully updated'
        }`
      );
      refetch();
    } catch (error) {
      toggleWorking(false);
      handleError(error);
    }
  };

  const updateQuestionsOrder = async ({ orderList, question_ids }) => {
    try {
      const { quiz } = state;
      const api = new QuizAPI();
      const { error } = await api.updateQuiz(quiz?.id, {
        order: orderList,
        question_ids,
      });
      if (error) return handleError(error);
      setState((prev) => ({ ...prev, dragged: false }));
    } catch (error) {
      handleError(error);
    }
  };

  const onToggleToast = (message) => {
    setState((prev) => ({
      ...prev,
      toastMessage: message,
      showToast: !state.showToast,
    }));
  };

  const addQuestion = async () => {
    try {
      setState((prev) => ({ ...prev, error: '' }));
      let { questions, quiz } = state;
      const publishedStatus = getStatus();
      let lastQuestionOrder = 0;
      if (!isEmpty(quiz?.questions)) {
        if (quiz?.questions[quiz?.questions.length - 1].order === null) {
          lastQuestionOrder = quiz?.questions.length + 1;
        } else
          lastQuestionOrder = quiz?.questions[quiz?.questions.length - 1].order;
      }
      questions.forEach((question) => {
        question.order = lastQuestionOrder + 1;
        lastQuestionOrder++;
      });

      quiz = { ...quiz, publishedStatus: publishedStatus.id };
      const question = questions.find((question) => question?.body === '');
      if (!isEmpty(question))
        return setState((prev) => ({
          ...prev,
          error: 'You cannot add an empty question!',
        }));
      for (let question of questions) {
        const { options } = question;
        const correctOption = options.find(
          (option) => option.is_correct === true
        );
        for (let option of options) {
          if (!option?.body)
            return setState((prev) => ({
              ...prev,
              error: 'You cannot add an empty option!',
            }));
        }
        if (isEmpty(correctOption))
          return setState((prev) => ({
            ...prev,
            error:
              'Please ensure that you have selected the correct answer for each question!',
          }));
      }
      toggleWorking(true);
      const question_ids = !isEmpty(quiz?.questions)
        ? quiz?.questions.map((q) => q?.id)
        : [];
      const order = !isEmpty(quiz?.questions)
        ? quiz?.questions.map((q, idx) => (q.order ? q.order : idx + 1))
        : [];
      quiz = { ...quiz, question_ids, order };
      await addQuestionsToQuiz({ quiz, questions });
      toggleWorking(false);
      refetch();
      onCloseModal();
      onToggleToast('Question has been successfully added');
    } catch (error) {
      toggleWorking(false);
      handleError(error);
    }
  };

  const getTopicType = (arr) => {
    return arr?.[0];
  };

  const extractTopicTypes = (selectedHierarchy) => {
    try {
      const { hierarchy } = selectedHierarchy;
      if (!isEmpty(hierarchy)) {
        if (curriculum === 'C.B.C Rwanda') {
          const subTopicAreaType = getTopicType(hierarchy.child_topic_types);
          const unitNameType = getTopicType(
            subTopicAreaType?.child_topic_types
          );
          const unitOutlineType = getTopicType(unitNameType?.child_topic_types);
          const titleType = getTopicType(unitOutlineType?.child_topic_types);
          const conceptType = getTopicType(titleType?.child_topic_types);
          return conceptType?.id;
        } else if (curriculum === '8-4-4') {
          const topicType = getTopicType(hierarchy?.child_topic_types);
          const subTopicType = getTopicType(topicType?.child_topic_types);
          const conceptType = getTopicType(subTopicType?.child_topic_types);
          return conceptType?.id;
        } else {
          const subTopicType = getTopicType(hierarchy?.child_topic_types);
          return subTopicType?.id;
        }
      }
    } catch (error) {
      setState((prev) => ({ ...prev, error }));
    }
  };

  const getTopicTypeIds = () => {
    let topicTypeIds = curriculumHierarchy.map((selectedHierarchy) =>
      extractTopicTypes(selectedHierarchy)
    );
    if (!isEmpty(topicTypeIds)) {
      topicTypeIds = topicTypeIds.filter(Boolean);
      setState((prev) => ({ ...prev, topicTypeIds }));
    }
  };

  useEffect(() => {
    const { subject } = state;
    if (!isEmpty(curriculumHierarchy) && !isEmpty(subject)) {
      const courses = selectDropdownMapper(
        curriculumHierarchy?.map((h) => h?.course)
      );
      const course = courses.find(
        (course) => course?.value === subject?.course?.parent_course_id
      );
      getTopicTypeIds();
      setState((prev) => ({
        ...prev,
        courses,
        course,
      }));
    }
  }, [curriculumHierarchy, state.subject]);

  // const handleEditorChange = (e) => {
  //   setState(prev => ({...prev,
  //     lessonBody: e.target.getContent(),
  //   }));
  // };

  const getStatus = (selectedStatus = 'published') =>
    statuses.find(
      (status) => status.name.toUpperCase() === selectedStatus?.toUpperCase()
    );

  const onUpdateLesson = async () => {
    const { lesson, lessonBody, quizName, quiz, isPastPaper } = state;
    if (isPastPaper) return onUpdateQuiz();
    const api = new LessonAPI();
    const quizApi = new QuizAPI();
    if (isEmpty(lesson)) {
      try {
        const { user } = decryptKey();
        const status = getStatus();
        let payload = {
          title: quizName,
          quiz_ids: [quiz?.id],
          body: lessonBody,
          created_by: user.id,
          status_id: status.id,
        };
        if (!isEmpty(quiz?.topics)) {
          payload = {
            ...payload,
            topic_id: quiz?.topics?.[0]?.id,
          };
        }
        toggleWorking(true);
        const { error } = await api.createLesson(payload);
        if (error) {
          toggleWorking(false);
          return handleError(error);
        }
        if (quizName !== quiz?.title) {
          await quizApi.updateQuiz(quiz?.id, {
            title: quizName,
          });
        }
        toggleWorking(false);
        onCloseModal();
        // await loadLesson();
        onToggleToast('Lesson has been successfully added');
        return;
      } catch (error) {
        toggleWorking(false);
        handleError(error);
      }
    } else {
      try {
        toggleWorking(true);
        const { error } = await api.updateLesson(lesson.id, {
          body: lessonBody,
          title: quizName,
        });
        if (error) {
          toggleWorking(false);
          return handleError(error);
        }
        if (quizName !== quiz?.title) {
          await quizApi.updateQuiz(quiz?.id, {
            title: quizName,
          });
        }
        toggleWorking(false);
        onCloseModal();
        // await loadLesson();
        onToggleToast('Lesson has been successfully updated');
      } catch (error) {
        toggleWorking(false);
        handleError(error);
      }
    }
  };

  const onToggelBackModal = () => {
    setState((prevState) => ({
      ...prevState,
      showGoBackModal: !prevState.showGoBackModal,
    }));
  };

  const onDragEnd = async (result) => {
    const { questions } = state;
    const { destination, source, draggableId } = result;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;
    const question = questions.find((question) => question?.id === draggableId);
    questions.splice(source.index, 1);
    questions.splice(destination.index, 0, question);

    setState((prev) => ({ ...prev, questions, dragged: true }));
  };

  useEffect(() => {
    let questionOrderList = [];
    let question_ids = [];
    state.questions.forEach((question, idx) => {
      questionOrderList.push(idx + 1);
      question_ids.push(question?.id);
    });
    if (!isEmpty(questionOrderList) && state.dragged) {
      updateQuestionsOrder({
        orderList: questionOrderList,
        question_ids,
      });
    }
  }, [state.questions?.length, state.dragged]);

  const {
    quiz,
    lessonBody,
    quizName,
    lesson,
    showEditLessonModal,
    showEditQuizModal,
    showAddQuestionModal,
    code,
    level,
    period,
    showToast,
    questions,
    showGoBackModal,
    errors,
    isPastPaper,
    toastMessage,
    subject,
    subjects,
    course,
    courses,
    subjectError,
    levelError,
    isFetchingTopics,
    topicNotFoundError,
    topics,
    searchTerm,
    selectedTopics,
  } = state;

  const { title, legacy_code } = quiz || {};
  const canUpdateLesson = isPastPaper
    ? quizName !== title
    : quizName !== title || lessonBody !== lesson?.body;
  let legacyLevel = !isEmpty(quiz?.levels) ? quiz.levels?.[0] : {};
  let legacyPeriod = !isEmpty(quiz?.periods) ? quiz.periods?.[0] : {};
  let legacySubject = !isEmpty(quiz?.subjects) ? quiz.subjects?.[0] : {};
  const canUpdateQuiz =
    level?.id !== legacyLevel?.id ||
    period?.id !== legacyPeriod?.id ||
    subject?.id !== legacySubject?.id ||
    !isEmpty(selectedTopics) ||
    code !== legacy_code;

  if (!isEmpty(periods)) {
    periods = selectDropdownMapper(periods);
  }
  const deletedStatus = getStatus('deleted');
  const text = isPastPaper
    ? 'Past paper'
    : showEditLessonModal
    ? 'this lesson'
    : 'quiz settings';
  return (
    <div className='add-quiz-container'>
      <ToastContainer
        show={showToast}
        onClose={onCloseModal}
        message={toastMessage}
      />
      <Button
        variant='link'
        className='btn-link'
        onClick={() =>
          isPastPaper
            ? history.push(routes.listExams)
            : history.push({
                pathname: routes.viewQuizzes,
                state: {
                  withConcept: !isEmpty(quiz?.topics) ? true : false,
                },
              })
        }
      >
        &larr; Go back
      </Button>
      <ConfirmModal
        title='Confirm Update'
        message={`Are you sure you want to update ${text}`}
        onSubmit={showEditLessonModal ? onUpdateLesson : onUpdateQuiz}
        show={showEditQuizModal || showEditLessonModal}
        working={working}
        onHide={onCloseModal}
      />
      <FormModalComponent
        title='Add Question'
        onSubmit={
          showGoBackModal
            ? () =>
                isPastPaper
                  ? history.push(routes.listExams)
                  : history.push({
                      pathname: routes.viewQuizzes,
                      state: {
                        withConcept: !isEmpty(quiz?.topics) ? true : false,
                      },
                    })
            : addQuestion
        }
        show={showAddQuestionModal || showGoBackModal}
        working={working}
        onHide={onCloseModal}
        action='Yes'
      >
        {error && <ErrorMessage message={error} />}
        {showGoBackModal ? (
          <p>
            You will lose any unsaved changes. Are you sure you want to
            continue?
          </p>
        ) : (
          <p>{`Are you sure you want to add question this ${
            isPastPaper ? 'Past paper' : 'quiz'
          }?`}</p>
        )}
      </FormModalComponent>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Row>
            <Col md={8}>
              <div className='lesson-settings'>
                <Form.Group>
                  <Form.Label>
                    {isPastPaper ? 'Past Paper Title:' : 'Quiz Name:'}
                  </Form.Label>
                  <Form.Control
                    name='quizName'
                    onChange={onChange}
                    value={quizName}
                    isInvalid={!!errors?.quizName}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors?.quizName}
                  </Form.Control.Feedback>
                </Form.Group>
                {!isPastPaper && (
                  <Form.Group>
                    <Form.Label>Lesson:</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows='5'
                      name='lessonBody'
                      value={lessonBody}
                      onChange={onChange}
                      isInvalid={!isPastPaper && !!errors?.lessonBody}
                    />
                    {!isPastPaper && canUpdateLesson && !errors?.lessonBody ? (
                      <Form.Text>
                        {' '}
                        Total number of chars in Lesson: {lessonBody?.length}
                        /600
                      </Form.Text>
                    ) : (
                      <Form.Control.Feedback type='invalid'>
                        {errors?.lessonBody}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                )}
              </div>
              <div className='update-lesson'>
                {canUpdateLesson && (
                  <Button
                    className='btn-primary'
                    onClick={() => onToggleModal('showEditLessonModal')}
                    disabled={!!errors?.quizName || !!errors?.lessonBody}
                  >
                    {isPastPaper ? 'Update title' : 'Update lesson'}
                  </Button>
                )}
              </div>
            </Col>
            <Col md={4}>
              <div className='quiz-settings'>
                <h6 className='settings-title'>Quiz Settings</h6>
                <Form.Group as={Col}>
                  <Form.Label>course</Form.Label>
                  <SelectInputField
                    name='course'
                    value={course}
                    onChange={onSelectChange('course')}
                    options={courses}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Subject</Form.Label>
                  <SelectInputField
                    name='subject'
                    value={subject}
                    onChange={onSelectChange('subject')}
                    options={subjects}
                  />
                  {subjectError && (
                    <Form.Text className='text-danger'>
                      {subjectError}
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Level</Form.Label>
                  <SelectInputField
                    name='level'
                    value={level}
                    onChange={onSelectChange('level')}
                    options={levels}
                  />
                  {levelError && (
                    <Form.Text className='text-danger'>{levelError}</Form.Text>
                  )}
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Period:</Form.Label>
                  <SelectInputField
                    name='level'
                    value={period}
                    onChange={onSelectChange('period')}
                    options={periods}
                  />
                </Form.Group>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Topics</Form.Label>
                    <InputGroup className='mb-3'>
                      <Form.Control
                        value={searchTerm}
                        onChange={({ target }) => {
                          onSearchTopics(target.value);
                        }}
                        placeholder='Search topics to attach'
                      />
                      {isFetchingTopics && (
                        <InputGroup.Text className='input-spinner'>
                          <Spinner animation='border' role='status' size='sm' />
                        </InputGroup.Text>
                      )}
                    </InputGroup>
                  </Form.Group>
                  {topicNotFoundError ? (
                    <Form.Text className='text-danger'>
                      {topicNotFoundError}
                    </Form.Text>
                  ) : (
                    topics?.map((topic, idx) => (
                      <div key={`${topic?.id}_${idx}`}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              type='checkbox'
                              checked={topic?.selected}
                              onChange={() =>
                                handleSelectedTopics({
                                  ...topic,
                                  selected: topic?.selected ? false : true,
                                })
                              }
                              color='primary'
                            />
                          }
                          label={topic?.name}
                        />
                      </div>
                    ))
                  )}
                </Col>
                <Divider />
                <Form.Group as={Col}>
                  <Form.Label>Quiz Code:</Form.Label>
                  <Form.Control
                    name='code'
                    value={code}
                    onChange={onChange}
                    type='text'
                    isInvalid={!isPastPaper && !!errors?.code}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors?.code}
                  </Form.Control.Feedback>
                  {canUpdateQuiz && (
                    <div className='quiz-settings-btn mt-2'>
                      <Button
                        onClick={() => onToggleModal('showEditQuizModal')}
                        className='btn-primary'
                        disabled={!!errors?.code}
                      >
                        Update Quiz Settings
                      </Button>
                    </div>
                  )}
                </Form.Group>
              </div>
            </Col>
          </Row>
          <h6 className='mb-2'>Questions</h6>
          <DragDropContext onDragEnd={onDragEnd}>
            <div>
              <Droppable droppableId={quiz?.id}>
                {(provided) => {
                  return (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {!isEmpty(questions) &&
                        orderBy(questions, ['order'], ['asc'])
                          .filter(
                            (question) =>
                              parseInt(question.status_id) !==
                              parseInt(deletedStatus.id)
                          )
                          .map((question, idx) => (
                            <React.Fragment key={question.id}>
                              <EditQuestionCard
                                refresh={refetch}
                                statuses={statuses}
                                onSuccess={onToggleToast}
                                key={question?.id}
                                deletedStatus={deletedStatus}
                                question={question}
                                isPastPaper={isPastPaper}
                                idx={idx}
                              />
                            </React.Fragment>
                          ))}
                      <Row className='mt-1'>
                        <AddQuestion
                          startIdx={
                            !isEmpty(questions)
                              ? questions.filter(
                                  (question) =>
                                    parseInt(question.status_id) !==
                                    parseInt(deletedStatus.id)
                                ).length
                              : 0
                          }
                          topic={{}}
                          goBack={onToggelBackModal}
                          isPastPaper={isPastPaper}
                          onAddQuestionsToQuiz={onAddQuestionsToQuiz}
                        />
                      </Row>
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </div>
          </DragDropContext>
        </>
      )}
    </div>
  );
};

export default EditQuizComponent;
