import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

export default function SpinnerButton({
  onClick,
  working,
  className,
  children,
  type='button',
  variant='primary'
}) {
  return (
    <Button type={type} variant={variant} className={className} onClick={onClick} disabled={working}>
      {working && <Spinner animation='border' size='sm' className='mr-2' />}
      {children}
    </Button>
  );
}
