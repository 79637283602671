import axios from 'axios';
import { decryptKey, encryptKey } from './authHelpers';
import { routes } from '../routes/index';
import { history } from './history';
import { LOCAL_STORAGE_KEY } from '../constants';

const { REACT_APP_USER_API_GATEWAY_URL } = process.env;

const url = REACT_APP_USER_API_GATEWAY_URL;

const logout = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
  history.push(routes.login);
  window.location.reload();
};

export const commonInterceptor = instance => {
  delete instance.OPTIONS;
  instance.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      if(error.response?.data?.msg === 'Missing Authorization Header') {
        logout()
      }
      const { url: reqUrl } = error?.config || {};
      if (
       error?.response && error?.response?.status === 401 &&
        reqUrl === `${url}auth/token/refresh`
      ) {
        logout();
      }
      if (
        error?.response?.status === 401 &&
        reqUrl !== `${url}auth/token/refresh`
      ) {
        let userInfo = decryptKey();
        const refresh_token = userInfo?.refresh_token || '';
        try {
          const response = await axios.post(`${url}auth/token/refresh`, null, {
            headers: {
              Authorization: `Bearer ${refresh_token}`,
            },
          });
          const { access_token } = response.data;
          userInfo = { ...userInfo, token: access_token };
          encryptKey(userInfo);
          return axios(reqUrl);
        } catch (err) {
          return Promise.reject(err);
        }
      }
      return Promise.reject(error);
    }
  );
};

commonInterceptor(axios);
delete axios.OPTIONS;

export default axios;
