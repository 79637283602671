import React from 'react';
import { Form, Col } from 'react-bootstrap';
import { MdCheckCircle } from 'react-icons/md';

const QuestionOption = ({
  label,
  isCorrect,
  body,
  explanation,
  questionIndex,
  handleOptionChange,
  isPastPaper,
  isPrimary,
}) => {
  let component = (
    <>
      <Form.Group as={Col}>
        {isPrimary ? (
          <>
            <Form.Label>
              Option {label}
              {isCorrect && <MdCheckCircle className='answer-icon' />}
            </Form.Label>
            <Form.Control
              type='text'
              name='body'
              value={body}
              onChange={handleOptionChange(questionIndex, label, 'body')}
            />
          </>
        ) : null}
        <Form.Label>Explanation</Form.Label>
        <Form.Control
          as='textarea'
          rows='3'
          name='explanation'
          value={explanation}
          isInvalid={!isPastPaper && explanation.length > 250}
          onChange={handleOptionChange(questionIndex, label, 'explanation')}
        />
        {isPastPaper ? (
          <Form.Text>
            {`Total number of chars in Explanation: ${explanation.length}`}
          </Form.Text>
        ) : explanation.length <= 250 ? (
          <Form.Text>
            Total number of chars in Explanation: {explanation.length}/250
          </Form.Text>
        ) : (
          <Form.Control.Feedback type='invalid'>
            Option explanation exceeds character limit
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </>
  );
  return component;
};

export default QuestionOption;
