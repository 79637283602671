import React, { Fragment, useEffect, useState } from 'react';
import {
  Form,
  Row,
  Col,
  Accordion,
  FormGroup,
  FormLabel,
  FormControl,
  Button,
} from 'react-bootstrap';
import QuestionOption from './question-options.component';
import { MdAddCircle, MdDelete } from 'react-icons/md';
import './questions.styles.scss';
import SelectInputField from '../../common/SelectInputField';
import { selectDropdownMapper } from '../../../utils/selectDropdownMapper';
import { isEmpty } from 'lodash';
import { decryptKey } from '../../../utils/authHelpers';
import { generateDropdownRange } from '../../../utils/generateDropdownRange';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useGetQuestionTypesQuery } from '../../../features/question/questionApiSlice';

const AddQuestion = (props) => {
  const [state, setState] = useState({
    showOptionTypes: false,
    isChecked: false,
    questions: [],
    questionTypeId: '',
    topci: {},
  });

  const { data } = useGetQuestionTypesQuery();

  const questionTypes = data?.returned_resultset;
  const types = selectDropdownMapper(
    questionTypes?.filter((type) => type.name.toLowerCase() === 'multi_choice')
  );

  useEffect(() => {
    const { isNationalPaper } = props;
    const type = questionTypes?.find(
      (type) => type.name.toLowerCase() === 'multi_choice'
    );
    if (!isEmpty(type)) {
      setState((prev) => ({ ...prev, questionTypeId: type.id }));
    }
    if (isNationalPaper) {
      const { status_id } = props;
      const { user } = decryptKey();
      setState((prev) => ({
        ...prev,
        questions: [
          ...state.questions,
          {
            body: '',
            status_id,
            created_by: user.id,
            question_type_id: 1,
            options: [
              {
                label: 'A',
                value: 'A',
                body: '',
                explanation: '',
                option_type_id: 1,
                is_correct: false,
              },
              {
                label: 'B',
                value: 'B',
                body: '',
                explanation: '',
                option_type_id: 1,
                is_correct: false,
              },
              {
                label: 'C',
                value: 'C',
                body: '',
                explanation: '',
                option_type_id: 1,
                is_correct: false,
              },
              {
                label: 'D',
                value: 'D',
                body: '',
                explanation: '',
                option_type_id: 1,
                is_correct: false,
              },
            ],
          },
        ],
      }));
    }
  }, [props]);

  const onAddQuestionsToQuiz = () => {
    const { questions } = state;
    questions.forEach((question) => {
      if (!question.question_type_id) {
        question.question_type_id = Number(types?.[0]?.id);
      }
    });
    props.onAddQuestionsToQuiz(questions);
  };

  const addQuestion = () => {
    const { status_id } = props;
    const { user } = decryptKey();
    const questions = [...state.questions];
    setState((prev) => ({
      ...prev,
      questions: [
        ...questions,
        {
          body: '',
          status_id,
          created_by: user.id,
          question_type_id: 1,
          options: [
            {
              label: 'A',
              value: 'A',
              body: '',
              explanation: '',
              option_type_id: 1,
              is_correct: false,
            },
            {
              label: 'B',
              value: 'B',
              body: '',
              explanation: '',
              option_type_id: 1,
              is_correct: false,
            },
            {
              label: 'C',
              value: 'C',
              body: '',
              explanation: '',
              option_type_id: 1,
              is_correct: false,
            },
            {
              label: 'D',
              value: 'D',
              body: '',
              explanation: '',
              option_type_id: 1,
              is_correct: false,
            },
          ],
        },
      ],
    }));
  };

  const toggleOptionTypes = () => {
    setState((prevState) => ({
      ...prevState,
      showOptionTypes: !prevState.showOptionTypes,
    }));
  };

  const removeQuestion = (idx) => {
    const questions = [...state.questions];
    questions.splice(idx, 1);
    setState((prev) => ({ ...prev, questions }));
  };

  const handleQuestionChange = (idx, e) => {
    const questions = [...state.questions];
    if (e?.value) {
      questions[idx] = {
        ...questions[idx],
        number: e.value,
      };
    } else {
      questions[idx] = {
        ...questions[idx],
        body: e.target.value,
        question_type_id: state.questionTypeId,
      };
    }
    setState((prev) => ({ ...prev, questions }));
  };

  const handleTypeChange = (idx) => (type) => {
    const questions = [...state.questions];
    questions[idx] = { ...questions[idx], question_type_id: type.value };
    setState((prev) => ({ ...prev, questions }));
  };

  const handleQuestionOptionChange =
    (idx, label, type) =>
    ({ target }) => {
      let questions = state.questions;
      const option = questions[idx].options.find(
        (option) => option.label === label
      );
      let optionIdx = questions[idx].options.indexOf(option);
      if (type === 'body') {
        questions[idx].options[optionIdx] = {
          ...questions[idx].options[optionIdx],
          body: target.value,
        };
      } else {
        questions[idx].options[optionIdx] = {
          ...questions[idx].options[optionIdx],
          explanation: target.value,
        };
      }
      setState((prev) => ({ ...prev, questions }));
    };

  const handleQuestionTagChange = (tag, value, idx) => {
    const questions = state.questions;
    questions[idx] = {
      ...questions[idx],
      [tag]: value,
    };
    setState((prev) => ({ ...prev, questions }));
  };

  const handleSelectCorrectAnswer = (idx) => (correctOption) => {
    let questions = state.questions;
    let optionIdx = questions[idx].options.indexOf(correctOption);
    // reset previously selected answer
    const correctAnswer = questions[idx].options.find(
      (option) => option.is_correct === true
    );
    let index;
    if (!isEmpty(correctAnswer)) {
      index = questions[idx].options.indexOf(correctAnswer);
      questions[idx].options[index] = {
        ...questions[idx].options[index],
        is_correct: false,
      };
    }
    questions[idx].options[optionIdx] = {
      ...questions[idx].options[optionIdx],
      is_correct: optionIdx === index ? false : true,
    };
    setState((prev) => ({ ...prev, questions }));
  };

  const handleCorrectAnswerChange = (target, idx, correctOption) => {
    setState((prev) => ({ ...prev, isChecked: target.checked }));
    handleSelectCorrectAnswer(idx)(correctOption);
  };

  const { questions, isChecked } = state;
  const {
    startIdx,
    goBack,
    isPastPaper,
    isNationalPaper,
    difficultyList,
    quizTitle,
    subject,
  } = props;

  return (
    <div className='add-question-card'>
      <Row className='question-body'>
        {isNationalPaper && (
          <Col md={12} className='mb-3 d-flex'>
            {!isEmpty(quizTitle) && (
              <h6 className='mr-2'>
                Paper: <span className='tag-wrapper'> {quizTitle}</span>
              </h6>
            )}
            {!isEmpty(subject) && (
              <h6>
                Subject: <span className='tag-wrapper'> {subject?.name}</span>
              </h6>
            )}
          </Col>
        )}
        {questions.map((question, idx) => (
          <Fragment key={idx}>
            <Col md={isNationalPaper ? 12 : 8}>
              <FormGroup as={Col}>
                <div className='question-body__label'>
                  <FormLabel>
                    Question{' '}
                    {!isNationalPaper ? (
                      <span>{startIdx ? idx + 1 + startIdx : idx + 1}:</span>
                    ) : (
                      'Number'
                    )}
                  </FormLabel>

                  {questions.length > 0 && (
                    <div
                      role='button'
                      onClick={() => removeQuestion(idx)}
                      className='question-delete'
                    >
                      <MdDelete /> Delete Question
                    </div>
                  )}
                </div>
                <Row>
                  {isNationalPaper && (
                    <Col md={4}>
                      <Row>
                        <Col md={12}>
                          <SelectInputField
                            value={question.number}
                            onChange={(value) =>
                              handleQuestionTagChange('number', value, idx)
                            }
                            placeholder='Select question number'
                            options={generateDropdownRange(1, 50)}
                          />
                        </Col>
                        <Col md={12}>
                          <Form.Group>
                            <Form.Label>Difficulty</Form.Label>
                            <SelectInputField
                              value={question.difficultyLevel}
                              onChange={(value) =>
                                handleQuestionTagChange(
                                  'difficultyLevel',
                                  value,
                                  idx
                                )
                              }
                              placeholder='Level of difficulty'
                              options={difficultyList}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>Time Duration (in Minutes)</Form.Label>
                            <SelectInputField
                              value={question.time}
                              onChange={(value) =>
                                handleQuestionTagChange('time', value, idx)
                              }
                              placeholder='Duration in minutes'
                              options={generateDropdownRange(0, 10)}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>Time Duration (in Seconds)</Form.Label>
                            <SelectInputField
                              value={question.sec}
                              onChange={(value) =>
                                handleQuestionTagChange('sec', value, idx)
                              }
                              placeholder='Duration in sec'
                              options={generateDropdownRange(1, 59)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  <Col md={isNationalPaper ? 8 : 12}>
                    <FormControl
                      as='textarea'
                      name='body'
                      cols={3}
                      value={question.body}
                      onChange={(e) => handleQuestionChange(idx, e)}
                      type='body'
                      isInvalid={!isPastPaper && question.body.length > 100}
                    />

                    {isPastPaper ? (
                      <Form.Text>
                        {`Total number of chars in Question: ${question.body.length}`}
                      </Form.Text>
                    ) : question.body.length <= 100 ? (
                      <Form.Text>
                        {`Total number of chars in Question: ${
                          question.body.length
                        } ${!isPastPaper && '/100'}`}
                      </Form.Text>
                    ) : (
                      <Form.Control.Feedback type='invalid'>
                        Question exceeds character limit
                      </Form.Control.Feedback>
                    )}
                    {isNationalPaper && (
                      <Accordion>
                        <Accordion.Toggle
                          as={Form.Text}
                          eventKey='0'
                          variant='link'
                          onClick={toggleOptionTypes}
                        >
                          <p className='link'>Hide or show options</p>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey='0'>
                          <div>
                            {!isEmpty(question.options) &&
                              question.options.map((option) => (
                                <React.Fragment key={option.label}>
                                  <QuestionOption
                                    label={option.label}
                                    isCorrect={option.is_correct}
                                    body={option.body}
                                    questionIndex={idx}
                                    isPastPaper={isPastPaper}
                                    handleOptionChange={
                                      handleQuestionOptionChange
                                    }
                                    explanation={option.explanation}
                                  />
                                  <Col>
                                    {isNationalPaper && (
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            type='checkbox'
                                            checked={
                                              isChecked && option.is_correct
                                            }
                                            onChange={({ target }) => {
                                              handleCorrectAnswerChange(
                                                target,
                                                idx,
                                                option
                                              );
                                            }}
                                            color='primary'
                                          />
                                        }
                                        label='Mark as Correct'
                                      />
                                    )}
                                  </Col>
                                </React.Fragment>
                              ))}
                            <Form.Group as={Col}></Form.Group>
                          </div>
                        </Accordion.Collapse>
                      </Accordion>
                    )}
                  </Col>
                </Row>
                <Row>
                  {!isNationalPaper && (
                    <Col md={12}>
                      <Accordion>
                        <Accordion.Toggle
                          as={Form.Text}
                          eventKey='0'
                          variant='link'
                          onClick={toggleOptionTypes}
                        >
                          <p className='link'>Hide or show options</p>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey='0'>
                          <div>
                            {!isEmpty(question.options) &&
                              question.options.map((option) => (
                                <React.Fragment key={option.label}>
                                  <QuestionOption
                                    label={option.label}
                                    isCorrect={option.is_correct}
                                    body={option.body}
                                    questionIndex={idx}
                                    isPastPaper={isPastPaper}
                                    handleOptionChange={
                                      handleQuestionOptionChange
                                    }
                                    explanation={option.explanation}
                                  />
                                  <Col>
                                    {isNationalPaper && (
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            type='checkbox'
                                            checked={
                                              isChecked && option.is_correct
                                            }
                                            onChange={({ target }) => {
                                              handleCorrectAnswerChange(
                                                target,
                                                idx,
                                                option
                                              );
                                            }}
                                            color='primary'
                                          />
                                        }
                                        label='Mark as Correct'
                                      />
                                    )}
                                  </Col>
                                </React.Fragment>
                              ))}
                            <Form.Group as={Col}></Form.Group>
                          </div>
                        </Accordion.Collapse>
                      </Accordion>
                    </Col>
                  )}
                </Row>
              </FormGroup>
            </Col>
            {!isNationalPaper && (
              <Col md={4}>
                <div className='question-type-container'>
                  <FormGroup as={Col}>
                    <FormLabel>Question Type:</FormLabel>
                    <SelectInputField
                      onChange={handleTypeChange(idx)}
                      value={types[0]}
                      options={types}
                    />
                  </FormGroup>

                  <FormGroup as={Col}>
                    <FormLabel>Correct Answer:</FormLabel>
                    <SelectInputField
                      onChange={handleSelectCorrectAnswer(idx)}
                      options={question.options}
                    />
                  </FormGroup>
                </div>
              </Col>
            )}
          </Fragment>
        ))}
        <Col md={12}>
          <div className='add-question-button'>
            <h6
              role='button'
              className='btn-add'
              onClick={addQuestion}
            >
              <MdAddCircle /> Add Question
            </h6>
          </div>
        </Col>
      </Row>
      <Row className='button-container'>
        <Col>
          <Button
            className='cms-btn btn-primary'
            disabled={!questions.length}
            onClick={onAddQuestionsToQuiz}
            variant='info'
          >
            Add to Quiz
          </Button>
          <Button
            className='cms-btn'
            variant='outline-secondary'
            onClick={goBack}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default AddQuestion;
