import React from 'react';
import Select from 'react-select';
import './styles.scss';
import classNames  from 'classnames';

export default function SelectInputField({
  onChange,
  value,
  isMulti = false,
  isSearchable = true,
  isLoading = false,
  options,
  onInputChange,
  readOnly= false,
  placeholder='Select an option',
  className = null,
  onBlur,
}) {
  return (
    <Select
      onChange={onChange}
      value={value}
      onInputChange={onInputChange}
      isLoading={isLoading}
      isSearchable={isSearchable}
      isMulti={isMulti}
      onBlur={onBlur}
      options={options}
      isDisabled={readOnly}
      placeholder={placeholder}
      className={classNames('select-field', className)}
    />
  );
}
