export const rolesMap = {
  API_ADMIN: 'API_ADMIN',
  ADMIN: 'ADMIN',
  CMS_EDITOR: 'CMS_EDITOR',
  CMS_ADMIN: 'CMS_ADMIN',
  AAT_RESPONDER: 'AAT_RESPONDER',
  AAT_RESPONDER_ADMIN: 'AAT_RESPONDER_ADMIN',
  AAT_ADMIN: 'AAT_ADMIN',
  SUPER_ADMIN: 'SUPER_ADMIN',
  SUBSCRIPTION_ADMIN: 'SUBSCRIPTION_ADMIN',
};


export const cmsRoles = ['API_ADMIN', 'CMS_ADMIN', 'CMS_EDITOR'];