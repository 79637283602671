import {  useCallback, useState } from 'react'

const useToggle = ( defaultValue = false) => {

    const [state, setState] = useState(defaultValue)

   const toggleValue = useCallback((newValue) => {
    setState(typeof newValue === 'boolean' ? newValue : !state)
   }, [state])

    return [state, toggleValue]
}

export default useToggle