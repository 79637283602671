import React from 'react';
import {
  MdMoreVert,
  MdEdit,
  MdDelete,
  // MdRemoveRedEye,
  MdAddCircle,
  MdRefresh,
  MdLink,
  MdRemoveRedEye,
  MdCheckCircle,
  MdRedo,
  MdComment,
} from 'react-icons/md';
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { Nav } from 'react-bootstrap';
import './styles.scss';
import { isEmpty } from 'lodash';
import { isCMSAdmin, getCurriculum } from '../../../utils/authHelpers';


const curriculum = getCurriculum();

export default function ContentActionButton({
  type,
  onEdit,
  onDelete,
  onRemoveTopic,
  onPreview,
  onRestore,
  onRedo,
  quizzes,
  onAddComment,
  onAdd,
  onApprove,
  onViewComments,
  onUnpublish,
  onPublish,
  canDeleteQuiz,
}) {
  let dropdownAction = (
    <>
      <DropdownItem className='content__icon--edit' onClick={onEdit}>
        <MdEdit />
        Edit
      </DropdownItem>

      <DropdownItem className='content__icon--delete' onClick={onDelete}>
        <MdDelete />
        Trash
      </DropdownItem>
    </>
  );
  switch (type) {
    case 'content':
      dropdownAction = (
        <>
          <DropdownItem className='content__icon--edit' onClick={onEdit}>
            <MdEdit />
            Edit
          </DropdownItem>

          <DropdownItem className='content__icon--delete' onClick={onDelete}>
            <MdDelete />
            Trash
          </DropdownItem>
        </>
      );
      break;
    case 'pastpaper':
      dropdownAction = (
        <>
          <DropdownItem className='content__icon--edit' onClick={onEdit}>
            <MdEdit />
            Edit
          </DropdownItem>
          {canDeleteQuiz && (
            <DropdownItem className='content__icon--delete' onClick={onDelete}>
              <MdDelete />
              Trash
            </DropdownItem>
          )}
        </>
      );
      break;
    case 'cbQuestion':
      dropdownAction = (
        <>
        <DropdownItem className='content__icon--preview' onClick={onPreview}>
            <MdRemoveRedEye />
            Preview
          </DropdownItem>
          <DropdownItem className='content__icon--edit' onClick={onEdit}>
            <MdEdit />
            Edit
          </DropdownItem>

          <DropdownItem
            className='content__icon--preview'
            onClick={onUnpublish}
          >
            <MdRedo />
            Unpublish
          </DropdownItem>
          <DropdownItem className='content__icon--delete' onClick={onDelete}>
            <MdDelete />
            Trash
          </DropdownItem>
        </>
      );
      break;
    case 'cbDraft':
      dropdownAction = (
        <>
          <DropdownItem className='content__icon--edit' onClick={onEdit}>
            <MdEdit />
            Edit
          </DropdownItem>
          <DropdownItem className='content__icon--delete' onClick={onDelete}>
            <MdDelete />
            Trash
          </DropdownItem>
          {isCMSAdmin() ? (
            <DropdownItem
              className='content__icon--publish'
              onClick={onPublish}
            >
              <MdCheckCircle />
              publish
            </DropdownItem>
          ) : (
            <DropdownItem
              className='content__icon--publish'
              onClick={onApprove}
            >
              <MdCheckCircle />
              Submit
            </DropdownItem>
          )}
        </>
      );
      break;
    case 'cbPending':
      dropdownAction = (
        <>
          <DropdownItem className='content__icon--preview' onClick={onPreview}>
            <MdRemoveRedEye />
            Preview
          </DropdownItem>

          {isCMSAdmin() ? (
            <>
              <DropdownItem className='content__icon--edit' onClick={onEdit}>
                <MdEdit />
                Edit
              </DropdownItem>
              <DropdownItem className='content__icon--edit' onClick={onPublish}>
                <MdCheckCircle />
                Publish
              </DropdownItem>
              <DropdownItem
                className='content__icon--add'
                onClick={onAddComment}
              >
                <MdComment />
                Add Comment
              </DropdownItem>
              <DropdownItem
                className='content__icon--view'
                onClick={onViewComments}
              >
                <MdComment />
                View Comment(s)
              </DropdownItem>
              <DropdownItem className='content__icon--edit' onClick={onRedo}>
                <MdRedo />
                Redo
              </DropdownItem>
              <DropdownItem
                className='content__icon--delete'
                onClick={onDelete}
              >
                <MdDelete />
                Trash
              </DropdownItem>
            </>
          ) : (
            !isCMSAdmin() && (
              <DropdownItem className='content__icon--edit' onClick={onRestore}>
                <MdRedo />
                Move to draft
              </DropdownItem>
            )
          )}
        </>
      );
      break;
    case 'cbTrashed':
      dropdownAction = (
        <>
          <DropdownItem className='content__icon--edit' onClick={onRestore}>
            <MdRefresh />
            Restore
          </DropdownItem>
        </>
      );
      break;
    case 'cbRedo':
      dropdownAction = (
        <>
          <DropdownItem className='content__icon--edit' onClick={onEdit}>
            <MdEdit />
            Edit
          </DropdownItem>
          <DropdownItem
            className='content__icon--edit'
            onClick={onViewComments}
          >
            <MdComment />
            View Comment(s)
          </DropdownItem>
        </>
      );
      break;
    case 'unit':
      dropdownAction = (
        <>
          <DropdownItem className='content__icon--edit' onClick={onEdit}>
            <MdEdit />
            Edit
          </DropdownItem>
          <DropdownItem className='content__icon--add' onClick={onAdd}>
            <MdAddCircle />
            Add Topic
          </DropdownItem>

          <DropdownItem className='content__icon--delete' onClick={onDelete}>
            <MdDelete />
            Trash
          </DropdownItem>
        </>
      );
      break;
    case 'topic':
      dropdownAction = (
        <>
          <DropdownItem className='content__icon--edit' onClick={onEdit}>
            <MdEdit />
            Edit
          </DropdownItem>
        <DropdownItem className='content__icon--add' onClick={onAdd}>
            <MdAddCircle />
            Add Sub-topic
          </DropdownItem>

          <DropdownItem className='content__icon--delete' onClick={onDelete}>
            <MdDelete />
            Trash
          </DropdownItem>
        </>
      );
      break;
    case 'sub-topic':
      dropdownAction = (
        <>
          <DropdownItem className='content__icon--edit' onClick={onEdit}>
            <MdEdit />
            Edit
          </DropdownItem>
          <DropdownItem className='content__icon--add' onClick={onAdd}>
            <MdAddCircle />
            Add Concept
          </DropdownItem>
          <DropdownItem className='content__icon--delete' onClick={onDelete}>
            <MdDelete />
            Trash
          </DropdownItem>
        </>
      );
      break;
    case 'concept':
      dropdownAction = (
        <>
          <DropdownItem className='content__icon--edit' onClick={onEdit}>
            <MdEdit />
            Edit
          </DropdownItem>

          {isEmpty(quizzes) && (
            <DropdownItem className='content__icon--add' onClick={onAdd}>
              <MdAddCircle />
              Add Quiz
            </DropdownItem>
          )}
          <DropdownItem className='content__icon--delete' onClick={onDelete}>
            <MdDelete />
            Trash
          </DropdownItem>
        </>
      );
      break;
    case 'ghana-sub-topic':
      dropdownAction = (
        <>
          <DropdownItem className='content__icon--edit' onClick={onEdit}>
            <MdEdit />
            Edit
          </DropdownItem>

          {isEmpty(quizzes) && (
            <DropdownItem className='content__icon--add' onClick={onAdd}>
              <MdAddCircle />
              Add Quiz
            </DropdownItem>
          )}
          <DropdownItem className='content__icon--delete' onClick={onDelete}>
            <MdDelete />
            Trash
          </DropdownItem>
        </>
      );
      break;
    case 'topic-area':
      dropdownAction = (
        <>
          <DropdownItem className='content__icon--edit' onClick={onEdit}>
            <MdEdit />
            Edit
          </DropdownItem>
          <DropdownItem className='content__icon--delete' onClick={onDelete}>
            <MdDelete />
            Trash
          </DropdownItem>
        </>
      );
      break;
    case 'sub-topic-area':
      dropdownAction = (
        <>
          <DropdownItem className='content__icon--edit' onClick={onEdit}>
            <MdEdit />
            Edit
          </DropdownItem>
          <DropdownItem className='content__icon--add' onClick={onAdd}>
            <MdAddCircle />
            Add unit name
          </DropdownItem>
          <DropdownItem className='content__icon--delete' onClick={onDelete}>
            <MdDelete />
            Trash
          </DropdownItem>
        </>
      );
      break;
    case 'unit-name':
      dropdownAction = (
        <>
          <DropdownItem className='content__icon--edit' onClick={onEdit}>
            <MdEdit />
            Edit
          </DropdownItem>
          <DropdownItem className='content__icon--add' onClick={onAdd}>
            <MdAddCircle />
            Add unit Outline
          </DropdownItem>
          <DropdownItem className='content__icon--delete' onClick={onDelete}>
            <MdDelete />
            Trash
          </DropdownItem>
        </>
      );
      break;
    case 'unit-outline':
      dropdownAction = (
        <>
          <DropdownItem className='content__icon--edit' onClick={onEdit}>
            <MdEdit />
            Edit
          </DropdownItem>
          <DropdownItem className='content__icon--add' onClick={onAdd}>
            <MdAddCircle />
            Add Title
          </DropdownItem>
          <DropdownItem className='content__icon--delete' onClick={onDelete}>
            <MdDelete />
            Trash
          </DropdownItem>
        </>
      );
      break;
    case 'unit-title':
      dropdownAction = (
        <>
          <DropdownItem className='content__icon--edit' onClick={onEdit}>
            <MdEdit />
            Edit
          </DropdownItem>
          <DropdownItem className='content__icon--add' onClick={onAdd}>
            <MdAddCircle />
            Add concept
          </DropdownItem>
          <DropdownItem className='content__icon--delete' onClick={onDelete}>
            <MdDelete />
            Trash
          </DropdownItem>
        </>
      );
      break;
    case 'withoutConcept':
      dropdownAction = (
        <>
          <DropdownItem className='content__icon--add' onClick={onAdd}>
            <MdAddCircle />
            {curriculum === '6-3-3-4' ? 'Attach sub topic' : 'Attach concept'}
          </DropdownItem>
          <DropdownItem className='content__icon--edit' onClick={onEdit}>
            <MdEdit />
            Edit
          </DropdownItem>
          <DropdownItem className='content__icon--delete' onClick={onDelete}>
            <MdDelete />
            Delete Quiz
          </DropdownItem>
        </>
      );
      break;
    case 'withConcept':
      dropdownAction = (
        <>
          <DropdownItem className='content__icon--edit' onClick={onEdit}>
            <MdEdit />
            Edit
          </DropdownItem>
          <DropdownItem
            className='content__icon--delete'
            onClick={onRemoveTopic}
          >
            <MdLink />
            Unlink Quiz
          </DropdownItem>
          <DropdownItem className='content__icon--delete' onClick={onDelete}>
            <MdDelete />
            Delete Quiz
          </DropdownItem>
        </>
      );
      break;
    case 'trashed':
      dropdownAction = (
        <>
          <DropdownItem className='content__icon--edit' onClick={onRestore}>
            <MdRefresh />
            Restore
          </DropdownItem>
        </>
      );
      break;
    default:
      break;
  }
  return (
    <Nav>
      <UncontrolledDropdown nav direction='down' className='content__icon'>
        <DropdownToggle nav>
          <MdMoreVert className='vertical-button' />
        </DropdownToggle>
        <DropdownMenu className='action-menu'>{dropdownAction}</DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  );
}
