import axios from './net'

const {
  REACT_APP_SUBSCRIPTION_APP_URL: baseURL,
} = process.env;

export const subscriptionInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
})