import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = { periods: [] };

export const periodSlice = createSlice({
  name: 'period',
  initialState,
  reducers: {
    setperiods: (state, { payload }) => {
      state.periods = payload;
    },
  },
});

export const { setperiods } = periodSlice.actions;

export const periodSelector = createSelector(
  (state) => state,
  (state) => state.period
);

export default periodSlice.reducer;
