import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = { statuses: [] };

export const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    setStatuses: (state, { payload }) => {
      state.statuses = payload;
    },
  },
});

export const { setStatuses } = statusSlice.actions;
const getStatus = (state) => state
export const statusSelector = createSelector(getStatus,  state => state.status)

export default statusSlice.reducer;
