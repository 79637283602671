import React, { useCallback, useEffect, useState } from 'react';
import { history } from '../../../../utils/history';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Image,
  InputGroup,
  Spinner,
} from 'react-bootstrap';
import '../styles.css';
import http from '../../../../utils/http';
import urls from '../../../../utils/uri';
import { debounce, isEmpty } from 'lodash';
import { MdFileUpload } from 'react-icons/md';
import SpinnerButton from '../../../common/SpinnerButton';
import { decryptKey, isCMSAdmin } from '../../../../utils/authHelpers';
import ConfirmModal from '../../../common/ConfirmModal';
import ToastContainer from '../../../common/toast';
import Loading from '../../../../utils/loader';
import EditOptionCard from './EditOptionCard';
import SelectInputField from '../../../common/SelectInputField';
import { generateDropdownRange } from '../../../../utils/generateDropdownRange';
import { FormControlLabel, Checkbox } from '@mui/material';
import { selectDropdownMapper } from '../../../../utils/selectDropdownMapper';
import { mimeTypes } from '../../../../utils/images';
import TextEditor from '../../../common/TextEditor';
import { useSelector } from 'react-redux';
import { statusSelector } from '../../../../features/status/statusSlice';
import { useParams } from 'react-router-dom';
import { levelSelector } from '../../../../features/level/levelSlice';
import { getCourseFromSubject } from '../../../../utils/getCourseFromSubject';

const paginationArgs = { limit: 1, offset: 0 };

const userObj = decryptKey();
const userInfo = userObj && userObj.user;

const optionLabels = ['A', 'B', 'C', 'D'];

const numberRange = generateDropdownRange(1, 60);

const EditCandidateBundleQuestion = () => {
  const { id } = useParams();

  const [isFetchingTopics, setIsFetchingTopics] = useState(false);
  const [topics, setTopics] = useState(() => []);
  const [subject, setSubject] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [selectedNumber, setSelectedNumber] = useState({});
  const [selectedTimeInMin, setSelectedTimeInMin] = useState({});
  const [selectedTimeInSec, setSelectedTimeInSec] = useState({});
  const [working, setWorking] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invalidQuestionError, setInvalidQuestionError] = useState({
    year: '',
    body: '',
    number: '',
  });
  const [invalidOptionError, setInvalidOptionError] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitRedo, setSubmitRedo] = useState(false);
  const [isMultipleChoice, setIsMultipleChoice] = useState(true);
  const [options, setOptions] = useState([]);
  const [numberTagType, setNumberTagType] = useState({});
  const [timeTagType, setTimeTagType] = useState({});
  const [question, setQuestion] = useState({
    tag_ids: [],
    body: '',
    question_type_id: '',
    attachment_type_id: 1,
    created_by: userInfo?.id,
    status_id: '',
    legacy_id: '',
    topic_ids: '',
    subject_ids: '',
    number: '',
    year: '',
    level_ids: '',
    file_attachment: '',
  });
  const [, setError] = useState('');
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [, setImage] = useState({ raw: '' });
  const [, setCorrectOption] = useState({});
  const [openEndedOption, setOpenEndedOption] = useState({
    body: '',
    explanation: '',
    option_type_id: 1,
    status_id: 1,
    is_correct: false,
    has_image: false,
  });

  const { statuses } = useSelector(statusSelector);
  const { levels: allLevels } = useSelector(levelSelector);

  let levels = [];

  if (!isEmpty(allLevels)) {
    if (subject?.course?.id) {
      levels = selectDropdownMapper(
        allLevels?.filter(
          (courseLevel) => courseLevel?.course_id === subject?.course?.id
        )
      );
    } else {
      levels = selectDropdownMapper(allLevels);
    }
  }

  const onOptionFileChange = (e, idx) => {
    if (e.target.files.length) {
      setImage({
        raw: e.target.files[0],
      });
      options[idx] = {
        ...options[idx],
        file_attachment: e.target.files[0],
        has_image: true,
        preview: URL.createObjectURL(e.target.files[0]),
      };
    }
    setOptions(options);
  };

  const onQuestionFileChange = (e) => {
    setQuestion({
      ...question,
      file_attachment: e.target.files[0],
      preview: URL.createObjectURL(e.target.files[0]),
    });
  };

  const handleSelectedTopics = (topic) => {
    const newTopics = topics?.map((t) => {
      if (t?.id === topic?.id) {
        t.selected = topic?.selected;
        setIsChecked(true);
      }
      return t;
    });
    setTopics(newTopics);
  };

  const getQuestion = async () => {
    setLoading(true);
    const _numberTagType = await getQuestionNumberAndDurationTagType('number');
    const _timeTagType = await getQuestionNumberAndDurationTagType('time');
    const result = await http.get({}, `${urls.cms}question/${id}`);
    if (result?.errored) {
      setLoading(false);
      return;
    }
    setLoading(false);
    if (result?.returned_resultset) {
      const question = result?.returned_resultset;
      if (question.options.length > 1) {
        question.options.forEach((option, idx) => {
          option.label = optionLabels[idx];
        });
        setOptions(question.options);
      } else setOpenEndedOption(question.options[0]);
      setQuestion({
        ...question,
        body: question.body,
        question_type_id: '',
        created_by: question.created_by,
        levels: selectDropdownMapper(question.levels),
        topics: question.topics,
      });

      if (question.tags) {
        const numberTag = question.tags.find(
          (t) => t?.tag_type_id?.toString() === _numberTagType?.id?.toString()
        );
        const timeTag = question.tags.find(
          (t) => t?.tag_type_id?.toString() === _timeTagType?.id?.toString()
        );
        if (numberTag) {
          setSelectedNumber({
            ...numberTag,
            label: numberTag.name,
            value: numberTag.name,
          });
        }
        if (timeTag) {
          const [min, sec] = timeTag?.name?.split(':');
          setSelectedTimeInMin({
            ...timeTag,
            label: min,
            value: min,
          });
          setSelectedTimeInSec({
            ...timeTag,
            label: sec,
            value: sec,
          });
        }
      }
      if (question?.question_type?.name === 'Open_ended') {
        setIsMultipleChoice(false);
      }
      setQuestionLevelsAndTopics(question);
      const sub = question.subjects?.pop();
      await getSubject(sub?.id);
    }
  };

  const getSubject = async (id) => {
    const result = await http.get({}, `${urls.cms}subject/${id}`);
    if (result?.errored) {
      return;
    }
    setSubject(result?.returned_resultset);
  };

  useEffect(() => {
    if (!id) {
      history.goBack();
    } else {
      getQuestion();
    }
  }, [id]);

  const setQuestionLevelsAndTopics = (questionObj) => {
    if (questionObj.topics) {
      questionObj.topics.forEach((topic) => {
        topic.selected = true;
      });
      setTopics([...topics, ...questionObj.topics]);
    }
  };

  const getQuestionNumberAndDurationTagType = async (name) => {
    const result = await http.get(
      { name: name, ...paginationArgs },
      `${urls.cms}tag-type`
    );
    if (!result?.errored) {
      setLoading(false);
      if (name === 'number') {
        setNumberTagType(result?.returned_resultset[0]);
      } else setTimeTagType(result?.returned_resultset[0]);
    }
    setLoading(false);
    return result?.returned_resultset[0];
  };

  const updateQuestionOptions = async (questionOptions) => {
    for (let option of questionOptions) {
      await http.put(
        {
          body: option.body,
          is_correct: option.is_correct,
          explanation: option.explanation,
        },
        `${urls.cms}option/${option?.id}`
      );
      if (option.file_attachment) {
        const formData = new FormData();
        formData.append('attachment_type_id', 1);
        formData.append('file_attachment', option.file_attachment);
        await http.put(formData, `${urls.cms}option/${option?.id}`);
      }
    }
  };

  const onCreateTag = async (tag_type_id, tag_name) => {
    let tagId;
    const res = await http.get(
      {
        name: tag_name?.toString(),
        tag_type_id,
      },
      `${urls.cms}tag`
    );
    if (!isEmpty(res.returned_resultset)) {
      tagId = res.returned_resultset.find(
        (tag) => tag.name === tag_name?.toString()
      )?.id;
      if (!tagId) {
        const result = await http.post(
          {
            tag_type_id,
            name: tag_name.toString(),
            description: `${tag_name} description`,
            created_by: userInfo?.id,
          },
          `${urls.cms}tag`
        );
        tagId = result?.returned_resultset[0]?.id;
      }
    } else {
      const result = await http.post(
        {
          tag_type_id,
          name: tag_name.toString(),
          description: `${tag_name} description`,
          created_by: userInfo?.id,
        },
        `${urls.cms}tag`
      );
      tagId = result?.returned_resultset[0]?.id;
    }
    return tagId;
  };

  const unLinkQuestionAttributes = async () => {
    const { tags, levels, topics } = question;
    if (!isEmpty(tags) && !isEmpty(levels) && !isEmpty(topics)) {
      const numberTag = !isEmpty(tags)
        ? tags?.find(
            (tag) =>
              tag?.tag_type_id?.toString() === numberTagType?.id?.toString()
          )
        : {};
      const level_ids = !isEmpty(levels)
        ? levels?.map((level) => level?.id)
        : [];
      const topic_ids = !isEmpty(topics)
        ? topics?.map((topic) => topic?.id)
        : [];
      const params = {
        level_ids,
        topic_ids,
        tag_ids: !isEmpty(numberTag) ? [numberTag.id] : [],
      };

      await http.delete(params, `${urls.cms}question/${question?.id}`);
    }
  };

  const onUpdateQuestion = async () => {
    try {
      let errors = {};
      if (!question.body)
        return setInvalidQuestionError({
          ...invalidQuestionError,
          body: 'Question cannot be empty',
        });

      if (isMultipleChoice) {
        const missingOption = options.find((option) => !option.body);
        if (missingOption) {
          return setInvalidOptionError(
            'Option cannot be blank, please fill in all options'
          );
        }
        const correctOption = options.find((option) => option.is_correct);
        if (!correctOption) {
          return setInvalidOptionError('Please select correct answer');
        }
      } else {
        if (!openEndedOption.explanation) {
          return setInvalidOptionError('Explanation cannot be empty');
        }
      }
      const selectedTopics = topics?.filter((topic) => topic?.selected);
      setWorking(true);
      if (!selectedNumber?.value) {
        errors.number = 'Please select question number';
      }
      if (!selectedTimeInMin?.value || !selectedTimeInSec?.value) {
        errors.duration = 'Please select duration';
      }
      if (isEmpty(selectedTopics)) {
        errors.topic = 'Please select topic(s)';
      }
      if (isEmpty(selectedLevels)) {
        errors.level = 'Please select class level(s)';
      }
      if (!isEmpty(errors)) {
        setWorking(false);
        return setFormErrors(errors);
      }
      const questionNumberId = await onCreateTag(
        numberTagType?.id,
        selectedNumber?.value
      );
      let duration;
      if (selectedTimeInMin?.value && selectedTimeInSec?.value) {
        duration = `${selectedTimeInMin?.value}:${selectedTimeInSec?.value}`;
      }
      if (selectedTimeInSec?.value && !selectedTimeInMin?.value) {
        duration = `0:${selectedTimeInSec?.value}`;
      }
      if (!selectedTimeInSec?.value && selectedTimeInMin?.value) {
        duration = `${selectedTimeInMin?.value}:0`;
      }
      const timeTagId = await onCreateTag(timeTagType?.id, duration);
      let tag_ids = [questionNumberId, timeTagId];
      const questionOptions = isMultipleChoice ? options : [openEndedOption];
      await updateQuestionOptions(questionOptions);
      await unLinkQuestionAttributes();
      await http.put(
        {
          body: question.body,
          topic_ids: !isEmpty(selectedTopics)
            ? selectedTopics?.map((topic) => Number(topic?.id))
            : [],
          level_ids: !isEmpty(selectedLevels)
            ? selectedLevels.map((level) => level?.value)
            : [],
          tag_ids,
          status_id:
            isSubmitting && isPublished && isCMSAdmin()
              ? getStatus('draft')?.id
              : isSubmitting || submitRedo
              ? getStatus('pending review')?.id
              : question?.status_id,
        },
        `${urls.cms}question/${question?.id}`
      );
      if (question.file_attachment) {
        const formData = new FormData();
        formData.append('attachment_type_id', 1);
        formData.append('file_attachment', question.file_attachment);
        await http.put(formData, `${urls.cms}question/${question?.id}`);
      }
      setWorking(false);
      setShowToast(true);
      history.goBack();
    } catch (error) {
      setWorking(false);
      setError(error);
    }
  };

  const searchTopics = async (name, subjectId) => {
    const status = statuses.find((s) => s.name?.toLowerCase() !== 'published');
    setIsFetchingTopics(true);
    let params = { name, offset: 0, limit: 6 };
    if (!isEmpty(status)) params.status_id = status?.id;
    if (subjectId) {
      params = { ...params, subject_ids: [subjectId] };
    }
    const result = await http.get(params, `${urls.cms}topic`);
    if (result?.errored) {
      setIsFetchingTopics(false);
      return;
    }
    setIsFetchingTopics(false);
    setTopics((prevState) => [
      ...prevState.filter((topic) => topic?.selected),
      ...result?.returned_resultset,
    ]);
  };

  useEffect(() => {
    if (question?.levels && levels.length > 0) {
      const questionLevels = question?.levels?.map((level) => level?.id);
      const gnLevels = levels?.filter((level) =>
        questionLevels?.includes(level.id)
      );
      setSelectedLevels(selectDropdownMapper(gnLevels));
    }
  }, [question.levels]);

  const delayedTopicSearch = useCallback(
    debounce(
      (searchText, subjectId) => searchTopics(searchText, subjectId),
      500
    ),
    []
  );

  const onSearchTopics = (name, subjectId) => {
    if (name) {
      delayedTopicSearch(name, subjectId);
    }
  };

  const getStatus = (status) =>
    statuses.find((s) => s.name.toLowerCase() === status.toLowerCase());

  const handleOptionChange = (option, idx) => {
    options[idx] = option;
    setOptions(options);
  };

  const handleCorrectAnswerChange = (event, idx) => {
    options.forEach((option) => {
      option.is_correct = false;
    });
    options[idx] = {
      ...options[idx],
      is_correct: event.target.checked,
    };
    setIsChecked(event.currentTarget.checked);
    setOptions(options);
    setCorrectOption({ ...options[idx], is_correct: event.target.checked });
  };

  const onConfirmUpdate = async () => {
    await onUpdateQuestion();
    // setShowUpdateModal(false);
  };

  const onCloseModal = () => {
    setShowUpdateModal(false);
    setShowCancelModal(false);
  };

  const onCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    if (question?.id && !isEmpty(statuses)) {
      setIsPublished(
        question.status_id?.toString() ===
          getStatus('published')?.id?.toString()
      );
    }
  }, [question, statuses]);

  let errors = [];
  Object.values(invalidQuestionError).forEach((value) => {
    if (value) {
      errors.push(value);
    }
  });

  if (invalidOptionError) errors.push(invalidOptionError);

  const { isPrimary } = getCourseFromSubject(subject);

  return (
    <>
      <ToastContainer
        show={showToast}
        message='Question successfully updated'
        onClose={() => setShowToast(false)}
      />
      <ConfirmModal
        title='Confirm Update'
        message={
          showCancelModal
            ? 'You could lose any unsaved work. Would you like to continue?'
            : 'You are about to update this question, do you want to proceed?'
        }
        onSubmit={showCancelModal ? onCancel : onConfirmUpdate}
        show={showUpdateModal || showCancelModal}
        working={working}
        onHide={onCloseModal}
        errors={formErrors}
      />
      <div className='curriculum-wrapper'>
        {loading ? (
          <Loading />
        ) : (
          <Form layout='vertical'>
            <Row gutter={8} className='add-question-container'>
              <Col md={12}>
                <Card className='mb-1'>
                  <Card.Body>
                    <Button variant='link' onClick={() => history.goBack()}>
                      &larr; Back
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card className='add-question-card'>
                  <Card.Title>Question Settings</Card.Title>
                  <Row>
                    <Col md={12}>
                      {' '}
                      Subject{' '}
                      <span className='tag-wrapper'>{subject.name}</span>
                    </Col>
                    <Col md={12}>
                      <Form.Group>
                        <Form.Label>Question Number</Form.Label>

                        <SelectInputField
                          value={selectedNumber}
                          onChange={(value) => {
                            setInvalidQuestionError({
                              ...invalidQuestionError,
                              number: '',
                            });
                            setFormErrors({ ...formErrors, number: '' });

                            setSelectedNumber(value);
                          }}
                          isInvalid={invalidQuestionError.number}
                          placeholder='Select question number'
                          options={numberRange}
                        />
                        {formErrors.number && (
                          <p className='text-danger'>{formErrors.number}</p>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Time Duration (in Minutes)</Form.Label>
                        <SelectInputField
                          value={selectedTimeInMin}
                          onChange={(value) => {
                            setInvalidQuestionError({
                              ...invalidQuestionError,
                              time: '',
                            });
                            setFormErrors({ ...formErrors, duration: '' });
                            setSelectedTimeInMin(value);
                          }}
                          isInvalid={invalidQuestionError.time}
                          placeholder='Duration in minutes'
                          options={generateDropdownRange(0, 10)}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {invalidQuestionError.time}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Time Duration (in Seconds)</Form.Label>
                        <SelectInputField
                          value={selectedTimeInSec}
                          onChange={(value) => {
                            setInvalidQuestionError({
                              ...invalidQuestionError,
                              sec: '',
                            });
                            setFormErrors({ ...formErrors, duration: '' });

                            setSelectedTimeInSec(value);
                          }}
                          isInvalid={invalidQuestionError.number}
                          placeholder='Duration in sec'
                          options={generateDropdownRange(1, 59)}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {invalidQuestionError.number}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {formErrors.duration && (
                      <Col md={12}>
                        <p className='text-danger'>{formErrors.duration}</p>
                      </Col>
                    )}
                    <Col md={12}>
                      <Form.Group>
                        <Form.Label>Class Level</Form.Label>
                        <SelectInputField
                          value={selectedLevels}
                          onChange={(value) => {
                            setInvalidQuestionError({
                              ...invalidQuestionError,
                              levels: '',
                            });
                            setFormErrors({ ...formErrors, level: '' });
                            setSelectedLevels(value);
                          }}
                          isMulti
                          isInvalid={!!invalidQuestionError.year}
                          placeholder='Select the Level(s)'
                          options={levels}
                        />
                      </Form.Group>
                      {formErrors.level && (
                        <p className='text-danger'>{formErrors.level}</p>
                      )}
                    </Col>
                    <Col md={12}>
                      <Form.Group>
                        <Form.Label>Topics</Form.Label>
                        <InputGroup className='mb-3'>
                          <Form.Control
                            onChange={({ target }) => {
                              setFormErrors({ ...formErrors, topic: '' });
                              return onSearchTopics(target.value, subject?.id);
                            }}
                            placeholder='Search topics to attach'
                          />
                          {isFetchingTopics && (
                            <InputGroup.Text className='input-spinner'>
                              <Spinner
                                animation='border'
                                role='status'
                                size='sm'
                              />
                            </InputGroup.Text>
                          )}
                        </InputGroup>
                        {formErrors.topic && (
                          <p className='text-danger'>{formErrors.topic}</p>
                        )}
                      </Form.Group>
                      {topics?.map((topic) => (
                        <div key={topic?.id}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                type='checkbox'
                                checked={isChecked && topic?.selected}
                                onChange={({ target }) => {
                                  handleSelectedTopics({
                                    ...topic,
                                    selected: topic?.selected ? false : true,
                                  });
                                  setIsChecked(target.checked);
                                }}
                                color='primary'
                              />
                            }
                            label={topic?.name}
                          />
                        </div>
                      ))}
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={8}>
                <Card className='add-question-card'>
                  <Row>
                    <Col md={12}></Col>
                    <Col md={12}>
                      <Row gutter={10}>
                        <Col md={12}>
                          <Form.Group>
                            <Form.Label>Question</Form.Label>
                            <TextEditor
                              className='form-control'
                              value={question.body}
                              name='body'
                              onChange={(e) => {
                                setInvalidQuestionError({
                                  ...invalidQuestionError,
                                  body: '',
                                });
                                setQuestion({
                                  ...question,
                                  body: e,
                                });
                              }}
                              isInvalid={!!invalidQuestionError.body}
                              placeholder='Type the question'
                            />
                            <Form.Control.Feedback type='invalid'>
                              {invalidQuestionError.body}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md={12}>
                          <div className='question-addons'>
                            <label htmlFor='question-upload-button'>
                              {question.preview ||
                              !isEmpty(question.attachments) ? (
                                <>
                                  <Image
                                    src={
                                      question.preview
                                        ? question.preview
                                        : question.attachments[0]?.url
                                    }
                                    width='100'
                                    height='100'
                                    rounded
                                  />
                                  <div
                                    role='button'
                                    className='file-upload-btn'
                                  >
                                    Change Image
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    role='button'
                                    className='file-upload-btn'
                                  >
                                    <MdFileUpload /> Attach image
                                  </div>
                                </>
                              )}
                            </label>
                            <input
                              type='file'
                              accept={mimeTypes.images.join(',')}
                              id='question-upload-button'
                              style={{ display: 'none' }}
                              onChange={onQuestionFileChange}
                            />
                          </div>
                        </Col>
                        {isPrimary ? (
                          options.map((option, idx) => {
                            return (
                              <React.Fragment key={idx}>
                                <EditOptionCard
                                  option={option}
                                  idx={idx}
                                  invalidOptionError={invalidOptionError}
                                  handleOptionChange={handleOptionChange}
                                  setInvalidOptionError={setInvalidOptionError}
                                  handleCorrectAnswerChange={
                                    handleCorrectAnswerChange
                                  }
                                  onOptionFileChange={onOptionFileChange}
                                />
                              </React.Fragment>
                            );
                          })
                        ) : (
                          <Col md={12}>
                            <Form.Group>
                            <Form.Label>Answer / Explanation</Form.Label>
                            <TextEditor
                              className='form-control'
                              value={openEndedOption.body}
                              isInvalid={!!invalidOptionError}
                              placeholder={`Option ${openEndedOption.label}`}
                              onChange={(value) => {
                                setOpenEndedOption({
                                  ...openEndedOption,
                                  body: value,
                                  explanation: value,
                                });
                              }}
                            />
                            <Form.Control.Feedback type='invalid'>
                              {invalidOptionError}
                            </Form.Control.Feedback>
                          </Form.Group>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col md={12}>
                <Card className='question-footer'>
                  {!isEmpty(errors) && (
                    <ul style={{ textAlign: 'center' }}>
                      {errors.map((err) => {
                        return (
                          <li className='text-danger' key={err}>
                            {err}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  <Form.Group style={{ textAlign: 'center', marginTop: '1em' }}>
                    <>
                      <SpinnerButton
                        onClick={() => {
                          setShowUpdateModal(true);
                        }}
                        variant='primary'
                        className='mr-1'
                      >
                        Save
                      </SpinnerButton>

                      <SpinnerButton
                        onClick={() => {
                          setSubmitRedo(true);
                          setShowUpdateModal(true);
                          setIsSubmitting(true);
                        }}
                        variant='primary'
                        className='mr-1'
                      >
                        {isPublished && isCMSAdmin() ? 'Unpublish' : 'Submit'}
                      </SpinnerButton>

                      <Button
                        variant='default'
                        onClick={() => {
                          setShowCancelModal(true);
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  </Form.Group>
                </Card>
              </Col>
            </Row>
          </Form>
        )}{' '}
      </div>
    </>
  );
};

export default EditCandidateBundleQuestion;
