import { useCallback, useState } from 'react';

const useError = (defaultValue = '') => {
  const [state, setState] = useState(defaultValue);

  const handleError = useCallback((error = '') => {
    if (typeof error === 'string') {
      setState(error);
    } else setState(error.message);
  }, []);

  return [state, handleError];
};

export default useError;
