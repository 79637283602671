import CryptoJS from 'crypto-js';
import { LOCAL_STORAGE_KEY } from '../constants';
import moment from 'moment';

import { routes } from '../routes';
import { history } from './history';

export const decryptKey = () => {
  const cipher = localStorage.getItem(LOCAL_STORAGE_KEY);
  const key = process.env.REACT_APP_ENCRYPTION_KEY;
  // if cipher is undefined, then the process below breaks
  if (cipher) {
    const bytes = CryptoJS.AES.decrypt(cipher, key);
    const userInfo = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(userInfo);
  }
  return;
};

export const encryptKey = (userInfo) => {
  const key = process.env.REACT_APP_ENCRYPTION_KEY;
  // Encrypt
  const data = CryptoJS.AES.encrypt(JSON.stringify(userInfo), key).toString();
  // set user info to local storage
  localStorage.setItem(LOCAL_STORAGE_KEY, data);
  localStorage.setItem('login_date', new Date());
};

const loggedInUserRoles = () => {
  let userRoles = [];
  const userInfo = decryptKey();
  if (userInfo && userInfo.user && userInfo.user.roles) {
    userRoles = [...userInfo.user.roles];
  }
  return userRoles;
};

export const isAuthorized = (allowedRoles) => {
  const userRoles = loggedInUserRoles();
  if (userRoles.length === 0 && !allowedRoles) return false;
  return userRoles.some((role) => allowedRoles.includes(role.name));
};

export const redirectToCurriculumOptionsPage = (roles) => {
  const userRoles = loggedInUserRoles();
  if (userRoles.length === 0 && !roles) return false;
  return userRoles.some((role) => roles.includes(role.name));
};

export const checkUser = () => {
  const userInfo = decryptKey();
  return userInfo;
};

export const switchCurriculum = (curriculum, backgroundColor) => {
  localStorage.setItem('curriculum', curriculum);
  localStorage.setItem('backgroundColor', backgroundColor);
};
export const getCurriculum = () => localStorage.getItem('curriculum');

export const userToken = () => {
  const userInfo = decryptKey();
  if (userInfo !== undefined) {
    return userInfo.token;
  }
};
export const isSuperAdmin = () => {
  const userRoles = loggedInUserRoles();
  const adminRoles = ['API_ADMIN'];
  return userRoles.some((role) => adminRoles.includes(role.name));
};

export const isCMSAdmin = () => {
  const userRoles = loggedInUserRoles();
  const adminRoles = ['CMS_ADMIN', 'API_ADMIN'];
  return userRoles.some((role) => adminRoles.includes(role.name));
};
export const isCMSEditor = () => {
  const userRoles = loggedInUserRoles();
  const adminRoles = ['CMS_EDITOR'];
  return userRoles.some((role) => adminRoles.includes(role.name));
};

export const logout = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
  localStorage.removeItem('curriculum');
  localStorage.removeItem('course');
  localStorage.removeItem('login_date');
  history.push(routes.login);
};
