import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = { quizTypes: [] };

export const quizTypeSlice = createSlice({
  name: 'quizType',
  initialState,
  reducers: {
    setQuizTypes: (state, { payload }) => {
      state.quizTypes = payload;
    },
  },
});

export const { setQuizTypes } = quizTypeSlice.actions;
const getquizTypes = (state) => state;
export const quizTypeSelector = createSelector(
  getquizTypes,
  (state) => state.quizType
);

export default quizTypeSlice.reducer;
