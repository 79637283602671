import BaseAPI from './base';

export default class QuizAPI extends BaseAPI {
  getQuiz = (id) => {
    const request = this.api.get(`/quiz/${id}`);
    return this.processRequest(request);
  };

  updateQuiz = (id, paylaod) => {
    const request = this.api.put(`/quiz/${id}`, {...paylaod})
    return this.processRequest(request);
  }

  removeAttachedTopic = (quizId, paylaod) => {
    const request = this.api.put(`/quiz-unlink/${quizId}`, {...paylaod});
    return this.processRequest(request)
  }

}
