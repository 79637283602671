import parseResponse from '../utils/apiResponseParser';
import { cmsAxiosInstance } from '../utils/cmsAxiosInstance';
import { subscriptionInstance } from '../utils/subscriptionInstance';

export default class BaseAPI {
  constructor() {
    this.api = cmsAxiosInstance;
    this.subApi = subscriptionInstance
  }

  processRequest = (request) => {
    return parseResponse(request)
  }
}
