import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { switchCurriculum } from '../../utils/authHelpers';
import { history } from '../../utils/history';
import { routes } from '../../routes';
import { FaBookReader } from 'react-icons/fa';
import { MdSchool } from 'react-icons/md';

export default function CurriculumOptionsModal({ onHide, show }) {
  const onSwitchCurriculum = (curriculum, backgroundColor) => {
    switchCurriculum(curriculum, backgroundColor);
    history.push(routes.dashboard);
    window.location.reload();
  };

  return (
    <Modal size='md' show={show} centered onHide={onHide}>
      <Modal.Header style={{ textAlign: 'center' }} closeButton>
        Select Curriculum
      </Modal.Header>
      <Modal.Body>
        <div className='row d-flex justify-content-around py-4'>
          <Button
            variant='success'
            onClick={() => onSwitchCurriculum('C.B.C Rwanda', '#00ffff')}
          >
            <FaBookReader /> CBC Rwanda&rarr;
          </Button>

          <Button
            variant='primary'
            onClick={() => onSwitchCurriculum('8-4-4', '#d9d2e9')}
          >
            <MdSchool /> 8-4-4 Kenya&rarr;
          </Button>
          <Button
            variant='warning'
            onClick={() => onSwitchCurriculum('6-3-3-4', '#ffe599')}
          >
            <MdSchool /> 6-3-3-4 Sierra Leone&rarr;
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
