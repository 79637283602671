import React from 'react';
import { Toast } from 'react-bootstrap';
import './styles.scss';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import classNames from 'classnames';

export default function ToastContainer({ message, show, onClose, error }) {
  return (
    <Toast
      show={show}
      onClose={onClose}
      className={classNames(
        'toast-container',
        error && 'toast-container__error'
      )}
      delay={2000}
      autohide
    >
      <Toast.Body>
       { error ? <MdCancel className='mr-1' /> : <MdCheckCircle className='mr-1' />} 
       {error ? error : message}
      </Toast.Body>
    </Toast>
  );
}
