import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from '../features/api/apiSlice';
import authReducer from '../features/auth/authSlice';
import statusReducer from '../features/status/statusSlice';
import curriculumReducer from '../features/curriculum/curriculumSlice';
import periodReducer from '../features/period/periodSlice';
import levelReducer from '../features/level/levelSlice';
import courseReducer from '../features/course/courseSlice';
import quizTypeReducer from '../features/quizTypes/quizTypeSlice';
import userReducer from '../features/users/userSlice';
import { cmsApiSlice } from '../features/api/cmsApiSlice';

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [cmsApiSlice.reducerPath]: cmsApiSlice.reducer,
    auth: authReducer,
    status: statusReducer,
    curriculum: curriculumReducer,
    period: periodReducer,
    level: levelReducer,
    course: courseReducer,
    quizType: quizTypeReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware, cmsApiSlice.middleware),
  devTools: true,
});
