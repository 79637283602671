import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import http from '../../../../utils/http';
import urls from '../../../../utils/uri';

const fetchQuizzes = (params) => {
  return http.get(params, `${urls.cms}quiz`);
};

export const useFetchQuizzesQuery = (params, enabled) => {
  const {
    isLoading,
    data = {},
    error,
    refetch,
    isError,
    isFetching,
  } = useQuery(['Quizzes', params], () => fetchQuizzes(params), {
    enabled,
    refetchOnWindowFocus: false,
  });
  const {
    limit,
    offset,
    returned_resultset: quizzes,
    returned_resultset_size,
    available_resultset_size,
  } = data;
  return {
    limit,
    offset,
    quizzes,
    returned_resultset_size,
    refetch,
    isError,
    isLoading,
    error,
    available_resultset_size,
    isFetching,
  };
};

export const saveOptions = async (request) => {
  let option_ids = [];
  const { options } = request;
  for (let option of options) {
    delete option.label;
    delete option.value;
    if (option.preview) delete option.preview;
    option.status_id = request.status_id;
    if (option.file_attachment) {
      let formData = new FormData();
      Object.keys(option).forEach((key) => {
        formData.append(key, option[key]);
      });
      formData.append('attachment_type_id', 1);
      option = formData;
    }
    const response = await http.post(option, `${urls.cms}option`);
    option_ids.push(response.returned_resultset[0]?.id);
  }
  delete request.options;
  request.option_ids = option_ids;
};

export const saveQuestions = async (quiz) => {
  const { questions = [] } = quiz;
  let question_ids = [];
  let questionOrderList = [];
  for (let question of questions) {
    // saveOptions
    question.status_id = quiz.publishedStatus;
    if (!question.bypassOrder) {
      if (!question.order) {
        questionOrderList.push(questions.indexOf(question) + 1);
      } else questionOrderList.push(question.order);
    }
    delete question.order;
    delete question.bypassOrder;
    await saveOptions(question);
    const response = await http.post(question, `${urls.cms}question`);
    question_ids.push(response.returned_resultset[0]?.id);
  }
  delete quiz?.questions;
  delete quiz.publishedStatus;
  quiz.question_ids = question_ids;
  quiz.order = questionOrderList;
};

export const addQuestionsToQuiz = async (payload) => {
  const {
    quiz: {
      id,
      publishedStatus,
      question_ids: questionIds,
      order: questionsOrder,
    },
    questions,
  } = payload;
  const quiz = { questions, publishedStatus };
  await saveQuestions(quiz);
  let { question_ids, order } = quiz;
  question_ids = [...question_ids, ...questionIds];
  order = [...order, ...questionsOrder];
  return http.put({ question_ids, order }, `${urls.cms}quiz/${id}`);
};


const updateTopic = async ({ id, level_ids, period_ids }) => {
  await http.put({level_ids, period_ids }, `${urls.cms}topic/${id}`, );
};

export const saveLesson = async ({
  title,
  topic_id,
  quiz_ids,
  body,
  created_by,
  status_id,
}) => {
  const lesson = { title, topic_id, quiz_ids, body, created_by, status_id };
  await http.post(lesson, `${urls.cms}lesson`);
};

export const createQuiz = async (quiz, onSuccess) => {
  try {
    const { level_ids, period_ids, topic_ids, quizId } = quiz;
    const status_id = quiz.publishedStatus;
    const lessonBody = quiz.lesson;
    delete quiz.lesson;
    delete quiz?.quizId;
    if (!isEmpty(quiz?.questions)) {
      await saveQuestions(quiz);
    }
    delete quiz?.questions;
    delete quiz.publishedStatus;
    if (quizId) {
      await http.put({
        question_ids: quiz?.question_ids,
        order: quiz.order,
      }, `${urls.cms}quiz/${quizId}`);
    } else {
      const response = await http.post(quiz, `${urls.cms}quiz`);
      const quiz_ids = [response.returned_resultset[0]?.id];
      if (lessonBody) {
        let lesson = {
          title: quiz?.title,

          body: lessonBody,
          created_by: quiz.created_by,
          status_id,
        };
        if (quiz?.topic_ids[0])
          lesson = { ...lesson, topic_id: quiz?.topic_ids[0] };
        await saveLesson({
          ...lesson,
          quiz_ids,
        });
      }
      if (!isEmpty(level_ids) && !isEmpty(period_ids)) {
        await updateTopic({ id: topic_ids[0], level_ids, period_ids });
      }
    }
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

