import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import SpinnerButton from './SpinnerButton';
import classNames from 'classnames';

export default function FormModalComponent({
  title,
  size = 'md',
  onHide,
  show,
  children,
  working,
  onSubmit,
  action = 'Save',
  close = 'Close',
  className,
  showSubmit = true
}) {
  return (
    <Modal size={size} show={show} centered onHide={onHide}>
      <Form onSubmit={onSubmit}>
        <Modal.Header
          className={classNames('modal-header', className)}
          closeButton
        >
          {title}
        </Modal.Header>
        <Modal.Body>
          {children}
          <div className='btn-footer'>
            <Button
              onClick={onHide}
              type='button'
              className='btn-secondary mr-3'
            >
              {close}
            </Button>
           {showSubmit && <SpinnerButton
              working={working}
              type='submit'
              onClick={onSubmit}
              className='btn-primary'
            >
              {action}
            </SpinnerButton>}
          </div>
        </Modal.Body>
      </Form>
    </Modal>
  );
}
