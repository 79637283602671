import React from 'react';
import { Spinner } from 'react-bootstrap';

export default function Loading() {
  return (
    <div className='spinner'>
      <Spinner animation='grow' variant='info' />
    </div>
  );
}
