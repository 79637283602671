
import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
// Add jquery.
import $ from 'jquery';
import tinymce from 'tinymce';

// Load wiris formula render script.
const jsDemoImagesTransform = document.createElement('script');
jsDemoImagesTransform.type = 'text/javascript';
jsDemoImagesTransform.src =
  'https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image';
document.head.appendChild(jsDemoImagesTransform);

// This needs to be included before the '@wiris/mathtype-tinymce5' is loaded synchronously
window.$ = $;
window.tinymce = tinymce; // Expose the TinyMCE to the window.

// Load wiris plugin synchronously.
require('@wiris/mathtype-tinymce5');

const TextEditor = ({
  onChange,
  className,
  value = '',
  configs = {},
  hideFormulas = false,
}) => {
  let external_plugins = {
    tiny_mce_wiris: `${window.location.href}/node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
  };
  if (hideFormulas) external_plugins = {};
  return (
    <Editor
      apiKey={process.env.REACT_APP_TINYMCE_KEY}
      value={value}
      init={{
        external_plugins,
        plugins: [],
        toolbar: [
          ' undo redo | bold italic | alignleft aligncenter alignright |' +
            'tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry ',
        ],
        htmlAllowedTags: ['.*'],
        htmlAllowedAttrs: ['.*'],
        ...configs,
      }}
      onEditorChange={onChange}
      className={className}
    />
  );
};

export default TextEditor;
