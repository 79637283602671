import React from 'react';
import classNames from 'classnames';
import { Button, Form, Modal } from 'react-bootstrap';
import SpinnerButton from './SpinnerButton';
import { isEmpty } from 'lodash';

export default function ConfirmModal({
  title,
  size = 'md',
  onHide,
  show,
  working,
  message,
  onSubmit,
  action = 'Yes',
  close = 'No',
  className,
  errors = {},
}) {

  const formErrors = Object.keys(errors).map(key => errors[key])?.filter(error => error)
  return (
    <Modal size={size} show={show} centered onHide={onHide}>
      <Form onSubmit={onSubmit}>
        <Modal.Header
          className={classNames('modal-header', className)}
          closeButton
        >
          {title}
        </Modal.Header>
        <Modal.Body>
          <p
            className={
              title === 'Confirm Delete' ? 'text-danger font-weight-bold' : ''
            }
          >
            {message}
          </p>
          {!isEmpty(formErrors) && (
            <>
              <p>Address the following errors before proceeding</p>
              {formErrors.map((error) => {
                return (
                  <>
                    <li key={error} className='text-danger'>
                      {error}
                    </li>
                  </>
                );
              })}
            </>
          )}
          <div className='btn-footer'>
            <Button onClick={onHide} type='button' className='btn-primary mr-3'>
              {close}
            </Button>
            <SpinnerButton
              working={working}
              type='button'
              onClick={onSubmit}
              className='btn-secondary'
            >
              {action}
            </SpinnerButton>
          </div>
        </Modal.Body>
      </Form>
    </Modal>
  );
}
