import { sortBy } from 'lodash';

export const selectDropdownMapper = (values = []) => {
    const dropdownList = values?.map(value => {
    if(value.period_number) {
      return {
        label: `${value.period_type.name} ${value.period_number}`,
        value: value.id,
        ...value
      }
    }
    if (value.level) {
      return {
        value: value.id,
        label: value.level,
        ...value
      };
    }
    if (value.names) {
      return {
        value: value.id,
        label: value.names,
        ...value
      };
    }
    return {
      value: value.id,
      label: value.name,
      ...value
    };
  });
  return sortBy(dropdownList, ['label'])
};
