import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import ContentActionButton from '../ContentActionButton/index';
import { history } from '../../../utils/history';
import ToastContainer from '../../common/toast';
import ConfirmModal from '../../common/ConfirmModal';
import moment from 'moment';
import QuizAPI from '../../../api/quiz';
import { isCMSAdmin } from '../../../utils/authHelpers';
import http from '../../../utils/http';
import urls from '../../../utils/uri';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../features/users/userSlice';
const PastPaperCard = (props) => {
  let { users } = useSelector(userSelector);

  const [state, setState] = useState({
    showQuestions: false,
    showDeleteModal: false,
    showRestoreModal: false,
    showConfirmModal: false,
    showToast: false,
    refresh: true,
    message: '',
  });

  const [loading, toggleLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [, setError] = useState(false);

  const isEnezaPaper = props.pathname?.includes('eneza');

  const handleError = (err) => {
    setError(err);
  };

  const onToggleConfirmationModal = (name) => {
    setState((prevState) => ({
      ...prevState,
      [name]: !prevState.name,
    }));
  };

  const onToggleToast = async () => {
    setState((prevState) => ({
      showToast: !prevState.showToast,
    }));
  };

  const getChildQuizzes = async (parent_quiz_id) => {
    const result = await http.get({ parent_quiz_id }, `${urls.cms}quiz`);
    const quizzes = result?.returned_resultset;

    if (!isEmpty(quizzes)) {
      quizzes.forEach((quiz) => {
        setQuestions((prevQuestions) => [...prevQuestions, ...quiz?.questions]);
      });
    }
  };

  useEffect(() => {
    const quiz = props?.quiz;
    let questions = quiz?.questions;
    if (!isEmpty(quiz?.subjects)) {
      const subject = quiz.subjects[0];
      if (subject?.name === 'English' || subject?.name === 'Kiswahili') {
        getChildQuizzes(quiz?.id);
      }
    }else setQuestions(questions);
  }, [props.quiz]);

  const onDeleteRestore = async () => {
    try {
      const { quiz, statuses, refresh } = props;
      const { showRestoreModal } = state;
      const api = new QuizAPI();
      let status = statuses.find((status) => status.name === 'DELETED');
      if (showRestoreModal) {
        status = statuses.find(
          (status) => status.name.toLowerCase() === 'pending review'
        );
      }
      toggleLoading(true);
      const { error } = await api.updateQuiz(quiz?.id, {
        status_id: status.id,
      });
      if (error) {
        toggleLoading(false);
        return handleError(error);
      }
      await refresh(quiz);
      await setState({
        message: showRestoreModal
          ? 'Past paper successfully restored'
          : 'Past paper successfully deleted',
      });
      await onToggleToast();
      await toggleLoading(false);
      await onCloseModal();
    } catch (error) {
      toggleLoading(false);
      handleError(error);
    }
  };

  const onCloseModal = () => {
    setState((prev) => ({
      ...prev,
      showConfirmModal: false,
      showUnlinkModal: false,
      showDeleteModal: false,
      showRestoreModal: false,
    }));
  };

  const handleEdit = (id) => {
    history.push({
      pathname: isEnezaPaper
        ? `/eneza/national-paper/${id}/edit`
        : `/national-paper/${id}/edit`,
      state: { pastPaper: true },
    });
  };
  const {
    showToast,
    message,
    showDeleteModal,
    showRestoreModal,
  } = state;
  const { quiz, selectedStatus, statuses, yearTagTypeId } = props;
  const { legacy_code, title, id, subjects, created_at } = quiz;
  let subject;

  if (!isEmpty(subjects)) {
    subject = subjects[0]?.name;
  }

  const canDeleteQuiz =
    isCMSAdmin() ||
    !isEmpty(
      statuses
        .filter((s) => ['draft'].includes(s.name?.toLowerCase()))
        .map((s) => s?.toString() === quiz.status_id?.toString())
    );
  const year = !isEmpty(quiz?.tags)
    ? quiz?.tags.find((tag) => tag.tag_type_id?.toString() === yearTagTypeId)
        ?.name
    : 'Not set';
  const user = users?.find((user) => user.id === quiz.created_by);
  const publishedStatus = statuses?.find(status => status?.name?.toLowerCase() === 'published')
  const publishedQuestions = questions?.filter(q => q?.status_id?.toString() === publishedStatus?.id?.toString())

  return (
    <>
      <ToastContainer
        show={showToast}
        onClose={onToggleToast}
        message={message}
      />
      <ConfirmModal
        title={showDeleteModal ? 'Confirm Delete' : 'Confirm Restore'}
        message={
          showDeleteModal
            ? 'Are you sure you want to delete this past paper?'
            : 'Are you sure you want to restore this past paper?'
        }
        onSubmit={onDeleteRestore}
        show={showDeleteModal || showRestoreModal}
        working={loading}
        onHide={onCloseModal}
      />
      <Row
        key={id}
        className={classNames('quiz-item', 'past-paper-card')}
        role='button'
      >
        <Col
          md={!isEnezaPaper ? 2 : 3}
          role='button'
          onClick={() => history.push(`national-papers/${id}`)}
        >
          {title}
        </Col>
        {!isEnezaPaper && (
          <Col md={1} className='topic-item'>
            {year}
          </Col>
        )}
        <Col md={2} className='topic-item'>
          {subject || '-'}
        </Col>
        <Col md={2} className='topic-item'>
          {!isEmpty(user) ? user.names : '-'}
        </Col>
        <Col md={2} className='topic-item'>
          {legacy_code}
        </Col>
        <Col md={1} className='topic-item text-center'>
          {!isEmpty(publishedQuestions) ? publishedQuestions.length : 0}
        </Col>
        <Col md={1} className='topic-item text-center'>
          {!isEmpty(questions) ? questions.length : 0}
        </Col>
        <Col md={1} className='topic-item actions-wrapper'>
          {created_at ? moment(created_at).format('L') : '-'}

          <ContentActionButton
            type={selectedStatus === 'deleted' ? 'trashed' : 'pastpaper'}
            onEdit={() => handleEdit(quiz?.id)}
            canDeleteQuiz={canDeleteQuiz}
            onDelete={() => onToggleConfirmationModal('showDeleteModal')}
            onRestore={() => onToggleConfirmationModal('showRestoreModal')}
          />
        </Col>
      </Row>
    </>
  );
};

export default PastPaperCard;
