import qs from 'querystring';
import { cmsApiSlice } from '../api/cmsApiSlice';
import { API_METHODS } from '../constants';

const { POST, PUT } = API_METHODS;

export const quizApiSlice = cmsApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getQuizzes: builder.query({
      query: (params) => {
        const queryParams = qs.stringify(params);
        return `/quiz?${queryParams}`;
      },
    }),
    getQuiz: builder.query({
      query: (id) => {
        return `/quiz/${id}`;
      },
    }),
    createQuiz: builder.mutation({
      query: (body) => ({
        url: `/quiz`,
        method: POST,
        body,
      }),
    }),
    updateQuiz: builder.mutation({
      query: (body) => ({
        url: `/quiz/${body?.id}`,
        method: PUT,
        body,
      }),
    }),
    deleteQuiz: builder.mutation({
      query: (id) => ({
        url: `/quiz/${id}`,
        method: PUT,
      }),
    }),
  }),
});

export const { useGetQuizQuery, useGetQuizzesQuery } = quizApiSlice;
