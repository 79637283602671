import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  levels: [],
};

export const levelSlice = createSlice({
  name: 'level',
  initialState,
  reducers: {
    setLevels: (state, { payload }) => {
      state.levels = payload;
    },
  },
});

export const { setLevels } = levelSlice.actions;

export const levelSelector = createSelector(
  state => state,
  state => state.level
);

export default levelSlice.reducer;
