import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import * as serviceWorker from "./serviceWorker";
import { history } from "./utils/history";
import { store } from "./app/store";
import "./index.scss";
import App from "./App";
import ErrorMessage from "./components/common/ErrorMessage";
import config from './config/index';

const queryClient = new QueryClient();


Bugsnag.start({
  apiKey: config.bugsnagApiKey,
  plugins: [new BugsnagPluginReact()],
});


const theme = createTheme({
  palette: {
    primary: {
      main: "#24c646",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#f01414;",
      contrastText: "#ffffff",
    },
  },
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
const ErrorView = () => (
  <ErrorMessage message={"something went wrong, try again later"} />
);

const render = (Component) => {
  const rootEl = document.getElementById("root");
  if (!(rootEl instanceof HTMLElement)) return;
  ReactDOM.render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <ErrorBoundary FallbackComponent={ErrorView}>
              <Component />
            </ErrorBoundary>
          </Router>
        </ThemeProvider>
      </QueryClientProvider>
    </Provider>,
    // </ApiProvider>,

    document.getElementById("root")
  );
};

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
