import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = { courses: [], selectedCourse: '',  };

export const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    setCourses: (state, { payload }) => {
      state.courses = payload;
    },
    setSelectedCourse: (state, { payload }) => {
      state.selectedCourse = payload;
    },
  },
});

export const { setCourses, setSelectedCourse } = courseSlice.actions;
export const courseSelector = createSelector(
  (state) => state,
  (state) => state.course
);

export default courseSlice.reducer;
