import axios from './net';
import qs from 'querystring';
import { userToken } from './authHelpers';
import { history } from './history';
import { routes } from '../routes/index';

export const get = async (
  endpoint,
  params = {},
  { authHeader = true, headers = {}, ...options } = {}
) => {
  let target = `${endpoint}?${qs.stringify(params)}`;
  let config = { headers, ...options };

  if (authHeader) {
    config.headers.Authorization = `Bearer ${userToken()}`;
  }

  try {
    const response = await axios.get(target, config);
    return response.data;
  } catch (ex) {
    if(ex.response) {

      const { status } = ex.response;
      if (status === 401) {
        return history.push(routes.login);
      }
    }


    return {
      errored: true,
      error: (ex.response ? ex.response.data.message : ex.message) || ex,
    };
  }
};

export const post = async (
  endpoint,
  data = {},
  {
    authHeader = true,
    headers = {
      'Content-Type': 'application/json',
    },
    ...options
  } = {}
) => {
  let config = { headers, ...options };

  if (authHeader) {
    config.headers.Authorization = `Bearer ${userToken()}`;
  }

  try {
    const response = await axios.post(endpoint, data, config);
    return response.data;
  } catch (ex) {
    return {
      errored: true,
      error: (ex.response ? ex.response.data.message : ex.message) || ex,
    };
  }
};
export const put = async (
  endpoint,
  data = {},
  { authHeader = true, headers = {}, ...options } = {}
) => {
  let config = { headers, ...options };

  if (authHeader) {
    config.headers.Authorization = `Bearer ${userToken()}`;
  }

  try {
    await axios.put(endpoint, data, config);
  } catch (ex) {
    return {
      errored: true,
      error: (ex.response ? ex.response.data.message : ex.message) || ex,
    };
  }
};

export const remove = async (
  endpoint,
  params = {},
  { authHeader = true, headers = {}, ...options } = {}
) => {
  let config = { headers, ...options };

  if (authHeader) {
    config.headers.Authorization = `Bearer ${userToken()}`;
  }

  try {
    await axios.delete(`${endpoint}?${qs.stringify(params)}`, config);
  } catch (ex) {
    return {
      errored: true,
      error: (ex.response ? ex.response.data.message : ex.message) || ex,
    };
  }
};


const http = {
  get: (params, endpoint) => get(endpoint, params),
  post: (data, endpoint, options) => post(endpoint, data, options),
  put: (data, endpoint, options) => put(endpoint, data, options),
  delete: (params, endpoint, options) => remove(endpoint, params, options),
};

export const bulkUpdateUtil = async(url, payload) => {
  return http.put(payload, url)
}

export default http;
