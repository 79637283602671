import BaseAPI from './base';
import { isEmpty } from 'lodash';
import qs from '../utils/querystring';

export default class LessonAPI extends BaseAPI {
  getLesson = (params = {}) => {
    let query = qs.stringify({});
    if (!isEmpty(params)) {
      query = qs.stringify(params);
    }
    const request = this.api.get(`/lesson?${query}`);
    return this.processRequest(request);
  };
  updateLesson = (id, paylaod) => {
    const request = this.api.put(`/lesson/${id}`, {...paylaod})
    return this.processRequest(request);
  }

  createLesson = (paylaod) => {
    const request = this.api.post('/lesson', {...paylaod})
    return this.processRequest(request);
  }

}
