import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { userToken } from '../../utils/authHelpers';
import { logOut } from '../auth/authSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: 'https://api.user.enezaeducation.com/api/v1',
  prepareHeaders: (headers, { getState }) => {
    let token = getState()?.auth?.token;
    if (!token) {
      token = userToken();
    }
    headers.set('authorization', `Bearer ${token}`);
    return headers;
  },
});

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  const response = await baseQuery(args, api, extraOptions);
  if (response?.error?.status === 401) {
    api.dispatch(logOut());
  }
  return response
};
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReAuth,
  // eslint-disable-next-line no-unused-vars
  endpoints: (builder) => ({}),
});
