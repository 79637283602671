import React, { Suspense, lazy, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { routes } from './routes';
import Loading from './utils/loader';
import { rolesMap } from './utils/roleConstants';
import EditQuizComponent from './components/Content/Quizzes/edit-quiz.component';
import ListNationalPapers from './components/Content/NationalPapers/ListNationalPapers';
import classNames from 'classnames';
import CurriculumOptionModal from './components/Curriculum/SwitchCurriculumModal';
import EditQuestion from './components/CandidateBundle/Questions/Detail/EditQuestion';
import CourseSelector from './components/Content/NationalPapers/CourseSelector';


const AddClozeTest = lazy(() =>
  import('./components/Content/NationalPapers/ClozeTest/AddClozeTest')
);
const EditComprehension = lazy(() =>
  import('./components/Content/NationalPapers/Comprehension/EditComprehension')
);
const CurriculumOptions = lazy(() => import('./components/Curriculum'));
const ViewVouchers = lazy(() => import('./components/Vouchers/view-vouchers'));

const AddComprehensionQuestions = lazy(() =>
  import('./components/Content/NationalPapers/Comprehension/AddComprehension')
);

const Logout = lazy(() =>
  import('./components/Accounts/Logout/logout.component')
);
const NationalPaperQuestions = lazy(() =>
  import('./components/CandidateBundle/Questions/List')
);

const ViewUser = lazy(() => import('./components/Users/view-user.component'));

const ViewQuestions = lazy(() =>
  import('./components/Content/Questions/view-questions.component')
);
const ViewTopics = lazy(() =>
  import('./components/Content/Topics/view-topics.component')
);

const SignIn = lazy(() =>
  import('./components/Accounts/SignIn/signIn.component')
);

const SideNav = lazy(() => import('./components/SideNav/side-nav.component'));
const AuthRouter = lazy(() =>
  import('./components/AuthRouter/auth-router.component')
);

const Header = lazy(() => import('./components/Header/header.component'));
const AddQuiz = lazy(() =>
  import('./components/Content/Quizzes/add-quiz.component')
);
const ViewQuizzes = lazy(() =>
  import('./components/Content/Quizzes/view-quizzes.component')
);
const AddQuestion = lazy(() =>
  import('./components/Content/Questions/add-question.component')
);
const ViewUsers = lazy(() => import('./components/Users/view-users.component'));
const ViewOrganisations = lazy(() =>
  import('./components/Organisations/view-organisations.component')
);
const TopicsHierachy = lazy(() =>
  import('./components/Content/TopicHierarchy')
);
const AttachQuizTopic = lazy(() =>
  import('./components/Content/Quizzes/attach-topic.component')
);

const AddEditNationalPaper = lazy(() =>
  import('./components/Content/NationalPapers/AddEditNationalPaper')
);

const ViewVoucherTypes = lazy(() =>
  import('./components/Vouchers/view-voucher-types')
);
const PublishedTags = lazy(() =>
  import('./components/CandidateBundle/Tags/List/index')
);
const CreateCandidateBundleQuestion = lazy(() =>
  import('./components/CandidateBundle/Questions/New/index')
);
const PreviewQuestion = lazy(() =>
  import('./components/CandidateBundle/Questions/Detail/PreviewQuestion')
);

const App = () => {

  const [showSideBar, setShowSideBar] = useState(true);
  const [showCurriculumModal, setShowCurriculumModal] = useState(false);

  const onShowSideBar = () => {
    setShowSideBar(!showSideBar);
  };
  const handleShowCurriculumModal = () => {
    setShowCurriculumModal(!showCurriculumModal);
  };

  const sidebarOpen = showSideBar ? 'open' : 'closed';
  return (
    <>
      <CurriculumOptionModal
        show={showCurriculumModal}
        onHide={handleShowCurriculumModal}
      />
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route
            exact
            path={routes.login}
            render={(props) => <SignIn {...props} />}
          />
          <Route
            title='Curriculum Options'
            path={routes.curriculum}
            render={(props) => <CurriculumOptions {...props} />}
          />
          <Route
            exact
            path={routes.logout}
            render={(props) => <Logout {...props} />}
          />
          <>
            <div className='wrapper'>
              <SideNav
                showSideBar={showSideBar}
                onShowSideBar={onShowSideBar}
              />
              <>
                <Header
                  onShowSideBar={onShowSideBar}
                  showSideBar={showSideBar}
                  onShowCurriculumModal={handleShowCurriculumModal}
                />
                <div className={classNames('main-content', sidebarOpen)}>
                  <Suspense fallback={<Loading />}>
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                      ]}
                      path={routes.content}
                      component={TopicsHierachy}
                    />

                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                      ]}
                      path={routes.addQuestion}
                      component={AddQuestion}
                    />

                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.listNationalPapers}
                      component={ListNationalPapers}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.courseSelector}
                      component={CourseSelector}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.listEnezaNationalPapers}
                      component={ListNationalPapers}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.addNationalPaper}
                      component={AddEditNationalPaper}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.addEnezaNationalPaper}
                      component={AddEditNationalPaper}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.editNationalPaper}
                      component={AddEditNationalPaper}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.editEnezaNationalPaper}
                      component={AddEditNationalPaper}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.viewNationalPaper}
                      component={NationalPaperQuestions}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.viewEnezaNationalPaper}
                      component={NationalPaperQuestions}
                    />

                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.candidateBundleQuestions}
                      type='Edit Quiz'
                      component={NationalPaperQuestions}
                    />
                    <AuthRouter
                      roles={[rolesMap.API_ADMIN]}
                      path={routes.tags}
                      type='Edit Quiz'
                      component={PublishedTags}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.addNationalPaperQuestions}
                      type='Add Question'
                      component={CreateCandidateBundleQuestion}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.addEnezaNationalPaperQuestions}
                      type='Add Question'
                      component={CreateCandidateBundleQuestion}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.addComprehensionQuestions}
                      type='Add Comprehension Question'
                      component={AddComprehensionQuestions}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.addEnezaComprehensionQuestions}
                      type='Add Comprehension Question'
                      component={AddComprehensionQuestions}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.addClozeTestQuestions}
                      type='Add Comprehension Question'
                      component={AddClozeTest}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.addEnezaClozeTestQuestions}
                      type='Add Comprehension Question'
                      component={AddClozeTest}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.viewComprehensionQuestions}
                      type='Add Comprehension Question'
                      component={NationalPaperQuestions}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.editComprehensionQuestions}
                      type='Add Comprehension Question'
                      component={EditComprehension}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.editEnezaComprehensionQuestions}
                      type='Add Comprehension Question'
                      component={EditComprehension}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.editCandidateBundleQuestion}
                      type='Edit Question'
                      component={EditQuestion}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.previewCandidateBundleQuestion}
                      type='Edit Question'
                      component={PreviewQuestion}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.previewEnezaCandidateBundleQuestion}
                      type='Edit Question'
                      component={PreviewQuestion}
                    />

                    <AuthRouter
                      roles={[rolesMap.API_ADMIN]}
                      path={routes.viewUser}
                      component={ViewUser}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                      ]}
                      title='Users'
                      path={routes.viewUsers}
                      component={ViewUsers}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                      ]}
                      title='Users'
                      path={routes.viewOrganisations}
                      component={ViewOrganisations}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                        rolesMap.SUBSCRIPTION_ADMIN,
                      ]}
                      title='Dashboard'
                      path={routes.dashboard}
                      component={TopicsHierachy}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.viewTopics}
                      type='Topic'
                      component={ViewTopics}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.viewQuizzes}
                      component={ViewQuizzes}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.addQuiz}
                      component={AddQuiz}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                      ]}
                      path={routes.editQuiz}
                      type='Edit Quiz'
                      component={EditQuizComponent}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                      ]}
                      path={routes.viewQuestions}
                      component={ViewQuestions}
                    />

                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_ADMIN,
                        rolesMap.CMS_EDITOR,
                        rolesMap.CMS_ADMIN,
                        rolesMap.AAT_RESPONDER,
                        rolesMap.AAT_RESPONDER_ADMIN,
                        rolesMap.SUBSCRIPTION_ADMIN,
                      ]}
                      title='Attach Topic'
                      path={routes.attachQuiz}
                      component={AttachQuizTopic}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.SUBSCRIPTION_ADMIN,
                      ]}
                      title='Vouchers'
                      path={routes.voucherTypes}
                      component={ViewVoucherTypes}
                    />
                    <AuthRouter
                      roles={[
                        rolesMap.API_ADMIN,
                        rolesMap.ADMIN,
                        rolesMap.SUBSCRIPTION_ADMIN,
                      ]}
                      title='Vouchers'
                      path={routes.vouchers}
                      component={ViewVouchers}
                    />
                  </Suspense>
                </div>
              </>
            </div>
          </>
        </Switch>
      </Suspense>
    </>
  );
};

export default App;
