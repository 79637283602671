import BaseAPI from './base';

export default class OptionAPI extends BaseAPI {
  getOption = (id) => {
    const request = this.api.get(`/option/${id}`);
    return this.processRequest(request);
  };

  updateOption = (id, paylaod) => {
    const request = this.api.put(`/option/${id}`, {...paylaod})
    return this.processRequest(request);
  }

  deleteOption = (id) => {
    const request = this.api.delete(`/option/${id}`)
    return this.processRequest(request);
  }

}
