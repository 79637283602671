export const routes = {
  dashboard: '/',
  login: '/login',
  logout: '/logout',
  register: '/register',
  content: '/content-master',
  addQuiz: '/add-quiz',
  addQuestion: '/add-question',
  viewQuestions: '/questions',
  addTip: '/add-tip',
  addLesson: '/add-lesson',
  viewQuizzes: '/quizzes',
  viewTopics: '/topics/:id',
  viewSubTopics: '/sub-topics',
  listNationalPapers: '/national-papers',
  courseSelector: '/select-course',
  listEnezaNationalPapers: '/eneza/national-papers',
  addNationalPaperQuestions: '/national-papers/:id/add-question',
  addEnezaNationalPaperQuestions: '/eneza/national-papers/:id/add-question',
  addComprehensionQuestions: '/national-papers/:id/comprehension-questions/add',
  addEnezaComprehensionQuestions: '/eneza/national-papers/:id/comprehension-questions/add',
  editComprehensionQuestions: '/national-papers/:id/comprehension-questions/edit',
  editEnezaComprehensionQuestions: '/eneza/national-papers/:id/comprehension-questions/edit',
  addClozeTestQuestions: '/national-papers/:id/cloze-test/add',
  addEnezaClozeTestQuestions: '/eneza/national-papers/:id/cloze-test/add',
  viewComprehensionQuestions: '/comprehension/:id/questions',
  addNationalPaper: '/add-national-papers',
  addEnezaNationalPaper: '/eneza/add-national-papers',
  editNationalPaper: '/national-paper/:id/edit',
  editEnezaNationalPaper: '/eneza/national-paper/:id/edit',
  viewNationalPaper: '/national-papers/:id',
  viewEnezaNationalPaper: '/eneza/national-papers/:id',
  viewUsers: '/users',
  viewOrganisations: '/organisation',
  viewUser: '/users/:id',
  viewRoles: '/user/roles',
  contentMasterDetails: '/master-details',
  listSetbooks: '/setbooks',
  attachQuiz: '/attach-topic/quiz/:id',
  editQuiz: '/quiz/:id',
  voucherTypes: '/vouchers',
  vouchers: '/voucher-type/:id/vouchers',
  curriculum: '/select-curriculum',
  changeSubject: '/change-subject',
  candidateBundleQuestions: '/candidate-bundle/questions',
  newCandidateBundleQuestion: '/candidate-bundle/questions/new/:id',
  tags: '/candidate-bundle/tags',
  editCandidateBundleQuestion: '/candidate-bundle/questions/edit/:id',
  previewCandidateBundleQuestion: '/candidate-bundle/questions/preview/:id',
  previewEnezaCandidateBundleQuestion: '/eneza/candidate-bundle/questions/preview/:id',
};
